/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

import { AuthContext } from 'containers/auth/Login';
import PropTypes from 'prop-types';
import { useCallback, useContext, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FaChartPie, FaStarHalfAlt, FaUserTie, FaVideo } from 'react-icons/fa';
import { RiMailForbidFill } from 'react-icons/ri';
import { HiLocationMarker } from 'react-icons/hi';
import { ImBlocked, ImFilter, ImUser } from 'react-icons/im';
import { MdReviews, MdTableChart } from 'react-icons/md';
import { withRouter } from 'react-router-dom';

import styles from './styles.module.css';


const activeColor = '#3B6EE1';

const Sidebar = ({ className, history, location }) => {
  const classes = [styles.container, className];
  const [expanded, setExpanded] = useState(false);
  const { claims } = useContext(AuthContext);
  const isAdmin = _.get(claims, 'admin') === true;
  const isManager = _.get(claims, 'manager') === true;
  const onToggle = () => setExpanded(!expanded);

  const onClick = useCallback((path) => () => {
    history.push(path);
  }, []);

  return (
    <Navbar className={classes.join(' ')} expand="xl"
      data-expanded={expanded}
      onToggle={onToggle} >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          {(isAdmin) && (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/locations'}
              onClick={onClick('/locations')}>
              <HiLocationMarker
                color={location.pathname === '/locations' ? activeColor : ''}
                size='20px' />
              <span>Locations</span>
            </Nav.Item>
          )}
          {(isManager) && (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/blacklists'}
              onClick={onClick('/blacklists')}>
              <RiMailForbidFill
                color={location.pathname === '/blacklists' ? activeColor : ''}
                size='20px' />
              <span>Blacklists</span>
            </Nav.Item>
          )}
          {isAdmin && (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/clients'}
              onClick={onClick('/clients')}>
              <FaUserTie
                style={{ marginLeft: 2 }}
                color={location.pathname === '/clients' ? activeColor : ''}
                size='17px' />
              <span>Clients</span>
            </Nav.Item>
          )}
          <Nav.Item
            className={styles.item}
            data-active={location.pathname === '/reviews'}
            onClick={onClick('/reviews')}>
            <MdReviews
              style={{ marginTop: 3 }}
              color={location.pathname === '/reviews' ? activeColor : ''}
              size='19px' />
            <span>Reviews</span>
          </Nav.Item>
          <Nav.Item
            className={styles.item}
            data-active={location.pathname.startsWith('/video-reviews')}
            onClick={onClick('/video-reviews')}>
            <FaVideo
              style={{ marginTop: 3 }}
              color={location.pathname.startsWith('/video-reviews') ? activeColor : ''}
              size='19px' />
            <span>Video Reviews</span>
          </Nav.Item>
          <Nav.Item
            className={styles.item}
            data-active={location.pathname === '/review-stats'}
            onClick={onClick('/review-stats')}>
            <FaChartPie
              style={{ marginLeft: 1, marginTop: 2 }}
              color={location.pathname === '/review-stats' ? activeColor : ''}
              size='17px' />
            <span>Review Stats</span>
          </Nav.Item>
          <Nav.Item
            className={styles.item}
            data-active={location.pathname === '/feedback-ratings'}
            onClick={onClick('/feedback-ratings')}>
            <FaStarHalfAlt
              style={{ marginLeft: 1, marginTop: -1 }}
              color={location.pathname === '/feedback-ratings' ? activeColor : ''}
              size='17px' />
            <span>Feedback Ratings</span>
          </Nav.Item>
          <Nav.Item
            className={styles.item}
            data-active={location.pathname === '/negative-reviews'}
            onClick={onClick('/negative-reviews')}>
            <ImBlocked
              style={{ marginTop: 1, marginLeft: 1 }}
              color={location.pathname === '/negative-reviews' ? activeColor : ''}
              size='16px' />
            <span>Negative Feedback</span>
          </Nav.Item>
          {isAdmin || isManager ? (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/historical-log'}
              onClick={onClick('/historical-log')}>
              <MdTableChart
                style={{ marginTop: 1, marginLeft: 1 }}
                color={location.pathname === '/historical-log' ? activeColor : ''}
                size='19px' />
              <span>Historical Log</span>
            </Nav.Item>
          ) : null}
          {isAdmin && (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/data-filters'}
              onClick={onClick('/data-filters')}>
              <ImFilter
                style={{ marginBottom: 1, marginLeft: 4 }}
                color={location.pathname === '/data-filters' ? activeColor : ''}
                size='16px' />
              <span>Data Filters</span>
            </Nav.Item>
          )}
          {isAdmin && (
            <Nav.Item
              className={styles.item}
              data-active={location.pathname === '/accounts'}
              onClick={onClick('/accounts')}>
              <ImUser
                style={{ marginBottom: 1, marginLeft: 4 }}
                color={location.pathname === '/accounts' ? activeColor : ''}
                size='16px' />
              <span>Accounts</span>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Sidebar.defaultProps = {
};
Sidebar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Sidebar);