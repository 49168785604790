/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import produce from 'immer';

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_LOAD_REVIEW_STATS_STATE': {
        draftState.loadReviewStatsState[action.locationId] = action.value;
        break;
      }
      case 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE': {
        draftState.loadMonthlyReviewStatsState = action.value;
        break;
      }
      default:
        return draftState;
    }
  });