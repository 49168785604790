/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineFieldTime, AiOutlineLock } from 'react-icons/ai';
import { FaMailBulk } from 'react-icons/fa';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button, IconButton } from 'components/buttons';
import { CloseIcon } from 'components/icons';
import { Bloc } from 'components/panels';
import { AppContext } from 'containers';
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful, isUseless } from 'utils';

import Header from '../Header';
import SearchResultMsg from '../SearchResultMsg';

import { ConfirmModal } from 'components/modals';
import { AuthContext } from 'containers/auth/Login';
import { findDeep } from 'utils/find';
import { BulkSendStatusList } from '../BulkSendForm';
import { cancelLogCampaign, loadData } from './actions';
import { reducer } from './reducer';

const HistoricalLog = ({ className }) => {
  const classes = [styles.container, className];
  // contexts
  const { useReducer } = React.useContext(AppContext);
  const { claims } = React.useContext(AuthContext);
  // state
  const [searchQuery, setSearchQuery] = useState('');
  const [bulkCampaignModalData, setBulkCampaignModalData] = useState(null);
  const [logToCancelCampaign, confirmLogCampaignCancellation] = useState(null);
  const [{
    loadState,
    loadMoreData,
    cancelLogCampaignState,
  }, dispatch] = useReducer(reducer, {
    loadState: {},
    loadMoreState: {},
    cancelLogCampaignState: {},
  }, {}, 'historical-logs');
  // effects
  useEffect(() => {
    if (isUseless(loadState))
      dispatch(loadData(claims));
  }, [loadState.status]);
  // effects
  useEffect(() => {
    if (isSuccessful(cancelLogCampaignState) && logToCancelCampaign != null)
      confirmLogCampaignCancellation(null);
  }, [cancelLogCampaignState]);
  // memos
  const searchMode = useMemo(() =>
    _.isEmpty(searchQuery) === false, [searchQuery]);

  return (
    <>
      <div className={classes.join(' ')}>
        <Header title='Historical Log' onSearch={setSearchQuery} />
        <AsyncStateSelector
          state={loadState}
          onProgress={() => <BackLoadingIndicator />}
          onFail={(error) => (
            <BackPrint>
              <p className='title'>Failed to load historical logs</p>
              <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
            </BackPrint>
          )}
          onSuccess={({ data, nextPageId }) => {
            if (searchMode) {
              data = findDeep(data, searchQuery);
            }
            return (
              <>
                {searchMode &&
                  <SearchResultMsg
                    text={`We found ${data.length} results for "${searchQuery}"`} />
                }
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2}>Date</th>
                      <th rowSpan={2}>Time</th>
                      <th rowSpan={2}>Listing</th>
                      <th rowSpan={2}>Texts<br />Sent</th>
                      <th rowSpan={2}>Emails<br />Sent</th>
                      <th colSpan="2">Reviews</th>
                      <th colSpan="2">Rating</th>
                      <th rowSpan={2}>Reviews<br />Collected</th>
                      <th rowSpan={2}>Negative<br />Reviews<br />Block</th>
                      <th rowSpan={2}>Notes</th>
                    </tr>
                    <tr>
                      <th>Before</th>
                      <th>After</th>
                      <th>Before</th>
                      <th>After</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(data, item => (
                      <React.Fragment key={item.uid}>
                        <tr className='spacer' />
                        <tr >
                          <td>{item.date}
                            {item.status === 'pending' &&
                              <AiOutlineFieldTime
                                className={styles.statusIcon}
                                style={{ cursor: 'pointer' }}
                                size='16px'
                                title='Scheduled'
                                onClick={() => confirmLogCampaignCancellation(item.uid)}
                              />}
                          </td>
                          <td>{item.time}</td>
                          <td>
                            {item.location.name}
                            {item.bulkCampaign &&
                              <FaMailBulk
                                className={styles.statusIcon}
                                style={{ cursor: 'pointer' }}
                                color={['finalized', 'cancelled'].includes(item.status) ? 'grey' : null}
                                size='14px'
                                title='Bulk Campaign'
                                onClick={() => setBulkCampaignModalData(item)}
                              />}
                            {item.status === 'finalized' &&
                              <AiOutlineLock
                                className={styles.statusIcon}
                                style={{ marginBottom: 3 }}
                                color='grey'
                                size='16px'
                                title='Finalized'
                              />}
                            <br />
                            <p className='locationAddress'>{item.location.address}</p>
                          </td>
                          <td>{item.smsSent}</td>
                          <td>{item.emailsSent}</td>
                          <td>{item.reviewsBefore}</td>
                          <td>{item.reviewsAfter}</td>
                          <td>{item.ratingBefore ? item.ratingBefore.toFixed(1) : ''}</td>
                          <td>{item.ratingAfter ? item.ratingAfter.toFixed(1) : ''}</td>
                          <td>{item.reviewsAfter != null && item.reviewsBefore != null
                            ? (item.reviewsAfter - item.reviewsBefore)
                            : ''}
                          </td>
                          <td>{item.negativeReviewsBlocked}</td>
                          <td>{item.note}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                {nextPageId &&
                  <center>
                    <Button
                      className={styles.loadMoreButton}
                      text='Load More'
                      loading={isInProgress(loadMoreData)}
                      onClick={() => dispatch(loadData(claims, nextPageId))}
                    />
                  </center>}
              </>
            );
          }}
        />
      </div >
      <Modal
        centered
        backdrop="static"
        show={bulkCampaignModalData != null}
        onHide={() => setBulkCampaignModalData(null)}>
        {bulkCampaignModalData != null &&
          <Bloc className={styles.bulkCampaignBloc}>
            <div className="header">
              <span>Bulk Campaign</span>
              <IconButton onClick={() => setBulkCampaignModalData(null)} >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="subheader">{bulkCampaignModalData.note}</div>
            <div className="body">
              <BulkSendStatusList
                destinations={bulkCampaignModalData.bulkCampaign.destinations}
              />
            </div>
          </Bloc>
        }
      </Modal>
      <ConfirmModal
        show={logToCancelCampaign != null}
        onDismiss={() => {
          confirmLogCampaignCancellation(null);
          dispatch({ type: 'UPDATE_CANCEL_LOG_CAMPAIGN_STATE', value: {} });
        }}
        confirmButtonProps={{
          className: 'danger',
          icon: <CloseIcon height={14} width={13} color='rgb(223, 44, 44)' />,
          text: 'Cancel',
          onClick: () => dispatch(cancelLogCampaign(logToCancelCampaign)),
          loading: isInProgress(cancelLogCampaignState),
        }}>
        <span className='title'>Cancel this campaign?</span>
        {isFailed(cancelLogCampaignState) && <span className='error'>{cancelLogCampaignState.error.message}</span>}
      </ConfirmModal>
    </>
  );
};

HistoricalLog.defaultProps = {
};
HistoricalLog.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array,
};

export default HistoricalLog;