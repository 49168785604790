/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

export function findDeep(arr, query) {
  const q = query.toUpperCase();
  const test = (obj) => {
    for (const value of _.values(obj)) {
      if (_.isObject(value) && test(value) ||
        value.toString().toUpperCase().includes(q))
        return true;
    }
    return false;
  };
  return arr.filter(test);
}