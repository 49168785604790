/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

import { LoadingIcon } from 'components/icons';

const LoadingIndicatorModal = ({ className, children, enabled, ...rest }) => {
  if (!enabled)
    return children;
  return (
    <div className={[className, styles.modal].join(' ')} {...rest} >
      {children}
      <LoadingIcon className={styles.loadingIcon} />
    </div>
  );
};

LoadingIndicatorModal.defaultProps = {
  enabled: false,
};
LoadingIndicatorModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  enabled: PropTypes.bool
};

export default LoadingIndicatorModal;