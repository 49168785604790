/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

import { ReviewStarIcon } from 'components/icons';
import { AppContext } from 'containers';
import { isFailed, isInProgress, isUseless } from 'utils';

import { GetFeedbackRatingsAction } from './actions';
import { reducer } from './reducer';

const FeedbackRatingList = ({ locations, onClick }) => {
  if (_.isEmpty(locations))
    return null;

  // contexts
  const { useReducer } = React.useContext(AppContext);
  // state
  const [{ getFeedbackRatings: stateMap }, dispatch] = useReducer(reducer, {
    getFeedbackRatings: {},
  }, {}, 'location-feedback-ratings');
  // effects
  useEffect(() => {
    for (const location of locations) {
      const state = _.get(stateMap, location.uid);
      if (isUseless(state)) {
        dispatch(GetFeedbackRatingsAction(location));
      }
    }
  }, [locations]);

  return (
    <div className={styles.list}>
      <Row>
        {locations.map((location) => {
          const state = _.get(stateMap, location.uid);
          return (
            <Col key={`location_${location.uid}`} lg="2" md="4" sm="5"
              className={onClick ? 'active' : ''}
              onClick={() => onClick && onClick(location)}>
              <p className='title'> {location.name}</p>
              {state == null || isInProgress(state)
                ? (
                  <ContentLoader viewBox="0 0 100 32">
                    <rect x="30" y="4" rx="4" ry="4" width="70" height="7" />
                    <rect x="50" y="14" rx="3" ry="3" width="50" height="5" />
                    <rect x="60" y="23" rx="3" ry="3" width="40" height="8" />
                  </ContentLoader>
                )
                : isFailed(state)
                  ? (
                    <div className={styles.error}>
                      <p className='title'>Failed to retrieve review stats</p>
                      <p className='message'>{_.get(state.error, 'message', 'Server Error')}</p>
                    </div>
                  )
                  : (
                    <div className={styles.content}>
                      <p className='rating'>
                        {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} height={18} width={18} fill={1} />)}
                        <span className={state.value.FIVE === 0 ? 'silent' : ''}>{state.value.FIVE}</span>
                      </p>
                      <p className='rating'>
                        {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} height={18} width={18} fill={i < 4} />)}
                        <span className={state.value.FOUR === 0 ? 'silent' : ''}>{state.value.FOUR}</span>
                      </p>
                      <p className='rating'>
                        {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} height={18} width={18} fill={i < 3} />)}
                        <span className={state.value.THREE === 0 ? 'silent' : ''}>{state.value.THREE}</span>
                      </p>
                      <p className='rating'>
                        {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} height={18} width={18} fill={i < 2} />)}
                        <span className={state.value.TWO === 0 ? 'silent' : ''}>{state.value.TWO}</span>
                      </p>
                      <p className='rating'>
                        {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} height={18} width={18} fill={i < 1} />)}
                        <span className={state.value.ONE === 0 ? 'silent' : ''}>{state.value.ONE}</span>
                      </p>
                    </div>
                  )
              }
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

FeedbackRatingList.defaultProps = {
};
FeedbackRatingList.propTypes = {
  locations: PropTypes.array,
  onClick: PropTypes.func,
};

export default FeedbackRatingList;