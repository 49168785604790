/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from 'lodash';
import styles from './styles.module.css';

import {
  faCheckCircle, faExclamationCircle, faFileExcel, faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { BackLoadingIndicator } from 'components/back';
import { Button, IconButton } from 'components/buttons';
import { DateTimePicker, DropdownField, Form } from 'components/forms';
import FormInputField from 'components/forms/FormInputField';
import { CloseIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import { isInProgress, isSuccessful, isUseless, parseBulkCampaignsFromExcel } from 'utils';

import { bulkSend } from './actions';
import { reducer } from './reducer';

const BulkSendForm = ({ className, onClose, locations, blacklist, dataFilters, data, ...rest }) => {
  const [redrawVal, redraw] = useState(0);
  const [isParsingExcelFile, setParsingExcelFile] = useState(false);
  const [controllers, setControllers] = useState((data || [{}]).map(formData => ({ formData })));
  const [activeFormIndex, setActiveFormIndex] = useState(0);

  const submitAll = useCallback(async () => {
    for (const controller of controllers) {
      if (isUseless(controller.submitState)) {
        setActiveFormIndex(controllers.indexOf(controller));
        if (!controller.submit())
          return;
        do {
          await new Promise(res => setTimeout(res, 300));
        } while (isInProgress(controller.submitState));
      }
    }
  }, [controllers]);

  // dropzone
  const onDrop = useCallback(files => {
    setParsingExcelFile(true);
    parseBulkCampaignsFromExcel(files[0], locations, blacklist, dataFilters,
      (error, data) => {
        if (error) {
          console.error('failed to parse excel file', error);
          toast.error('Failed to parse Excel file!');
          setParsingExcelFile(false);
          return;
        }

        if (_.isEmpty(data)) {
          toast.warn('No valid data found in the file');
        } else {
          setControllers(data.map((formData) => ({ formData })));
        }
        setParsingExcelFile(false);
      });
  });

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, noClick: true, noKeyboard: true });

  return (
    <Bloc className={[styles.bloc, className].join(' ')} {...rest} {...getRootProps()}>
      <div className={styles.header}>
        <span>Bulk Send</span>
        <input {...getInputProps()} />
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      {isDragActive ? (
        <div className={styles.dropzone}>
          <FontAwesomeIcon icon={faFileExcel} color='#9d9b9b' size='3x' />
          <p>Drop Excel File Here</p>
        </div>
      ) : isParsingExcelFile ? (
        <div className={styles.dropzone}>
          <BackLoadingIndicator height='45px' />
          <p>Parsing Excel File...</p>
        </div>
      ) : (
        <>
          {controllers.length > 1 && (
            <div className={styles.caruselDots}>
              {controllers.map((_ctrl, idx) => {
                const submitStatus = _.get(controllers[idx].submitState, 'status');
                const location = locations.find(l => l.uid === _.get(controllers[idx].formData, 'location'));
                return (
                  <OverlayTrigger
                    key={`dot_${idx}`}
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-${idx}`}>
                        <p>{_.get(location, 'name')}</p>
                        <p>{_.get(location, 'address')}</p>
                      </Tooltip>
                    }>
                    <div
                      className={[styles.caruselDot, activeFormIndex === idx && styles.caruselDotActive].join(' ')}
                      onClick={() => setActiveFormIndex(idx)} >
                      {submitStatus === 'failed'
                        ? <FontAwesomeIcon icon={faExclamationCircle} size='sm' color='red' />
                        : submitStatus === 'success'
                          ? <FontAwesomeIcon icon={faCheckCircle} size='sm' color='green' />
                          : submitStatus === 'in-progress'
                            ? <FontAwesomeIcon icon={faSpinner} size='sm' color='gray' />
                            : null}
                    </div>
                  </OverlayTrigger>
                );
              })}
            </div>
          )}
          {controllers.map((_ctrl, idx) => (
            <BulkCampaignForm
              key={`form_${idx}`}
              visible={idx === activeFormIndex}
              locations={locations}
              controller={controllers[idx]}
              onStateChange={() => redraw(redrawVal + 1)}
            />
          ))}
          <Button
            className={styles.sendButton}
            text={isSuccessful(controllers[activeFormIndex].submitState) ? 'Done' : 'Send'}
            onClick={controllers[activeFormIndex].submit}
            loading={isInProgress(controllers[activeFormIndex].submitState)}
            disabled={isSuccessful(controllers[activeFormIndex].submitState)}
          />
          {controllers.length > 1 &&
            <Button
              className={styles.sendButton}
              text="Send All"
              onClick={submitAll}
              loading={controllers.find(c => _.get(c.submitState, 'status') === 'in-progress')}
              disabled={controllers.find(c => _.get(c.submitState, 'status') !== 'success') == null}
            />
          }
        </>
      )}
    </Bloc>
  );
};

BulkSendForm.defaultProps = {
};
BulkSendForm.propTypes = {
  locations: PropTypes.array,
  blacklist: PropTypes.object,
  dataFilters: PropTypes.array,
  data: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default BulkSendForm;

/**
 * BulkCampaignForm 
 */
const BulkCampaignForm = ({ controller, onStateChange, locations, visible }) => {
  const { useReducer } = React.useContext(AppContext);

  // state
  const [{
    submitState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, {
    submitState: {},
    formData: controller.formData || {},
    formErrors: {},
  });

  controller.formData = formData;
  if (controller.submitState != submitState) {
    controller.submitState = submitState;
    onStateChange();
  }

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(submitState, 'error.message', ''),
    }, [submitState.error]);
  }, [submitState.error]);

  //  callbacks
  if (!controller.submit) {
    controller.submit = () => {
      const data = controller.formData;
      if (_.isEmpty(data.location))
        return dispatch({ type: 'UPDATE_FORM_ERROR', path: 'location', value: 'No location selected' });
      if (_.isEmpty(data.destinations))
        return dispatch({ type: 'UPDATE_FORM_ERROR', path: 'destinations', value: 'No destination' });
      dispatch(bulkSend(data));
      return true;
    };
  }

  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  const showStateWidget = isInProgress(submitState) || isSuccessful(submitState);
  return (
    <Form
      className={[styles.form, visible ? 'visible' : 'hidden'].join(' ')}
      error={_.get(formErrors, 'form')}>
      <DropdownField
        name='location'
        as="select"
        label='Location'
        placeholder='Select Location'
        value={_.get(formData, 'location', '')}
        error={_.get(formErrors, 'location', '')}
        onChange={onChange}
        disabled={isInProgress(submitState) || isSuccessful(submitState)}
        options={locations.map(location => ({
          value: location.uid,
          name: location.name + ' (' + location.address + ')',
        }))} />
      <FormInputField
        className={styles.destinations}
        as={showStateWidget ? 'div' : 'textarea'}
        name='destinations'
        label="Destinations"
        placeholder='Emails and/or Phone Numbers, new line separated'
        value={_.get(formData, 'destinations', '')}
        error={_.get(formErrors, 'destinations', '')}
        onChange={onChange}
        disabled={isInProgress(submitState) || isSuccessful(submitState)}
      >
        {showStateWidget &&
          <BulkSendStatusList destinations={submitState.value} />}
      </FormInputField>
      <FormInputField
        name='historicalLogNote'
        label="Historical Log Notes"
        placeholder="Enter a Note"
        type="text"
        value={_.get(formData, 'historicalLogNote', '')}
        error={_.get(formErrors, 'historicalLogNote', '')}
        onChange={onChange}
        disabled={isInProgress(submitState) || isSuccessful(submitState)} />
      <DateTimePicker
        className={styles.dateTimePicker}
        name='initializeAt'
        label="Schedule"
        placeholder="Schedule Bulk Campaign"
        value={_.get(formData, 'initializeAt', '')}
        error={_.get(formErrors, 'initializeAt', '')}
        onChange={onChange}
        disabled={isInProgress(submitState) || isSuccessful(submitState)} />
    </Form>
  );
};

BulkCampaignForm.defaultProps = {
};
BulkCampaignForm.propTypes = {
  locations: PropTypes.array.isRequired,
  controller: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  onStateChange: PropTypes.func.isRequired,
};

/**
 * BulkSendStatusList
 */
export const BulkSendStatusList = ({ className, destinations, ...rest }) => {
  const widgets = destinations.map((item, idx) => {
    const classes = [styles.sendStateItem, className];
    var icon;
    switch (item.status) {
      case 'sent':
        icon = <FontAwesomeIcon icon={faCheckCircle} color='green' />;
        break;
      case 'sending':
        icon = <FontAwesomeIcon icon={faSpinner} color='grey' />;
        break;
      case 'error':
        classes.push(styles.sendStateItemError);
        icon = <span style={{ color: '#ff4444' }}>{item.message} <FontAwesomeIcon icon={faExclamationCircle} /></span>;
        break;
    }
    return (
      <p key={`send_state_item_${idx}`} className={classes.join(' ')} {...rest}>
        <span>{item.value}</span>
        {icon}
      </p>
    );
  });
  return (<>{widgets}</>);
};
BulkSendStatusList.defaultProps = {
};
BulkSendStatusList.propTypes = {
  destinations: PropTypes.array,
  className: PropTypes.string,
};