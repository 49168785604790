/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

import { LoadingIcon } from 'components/icons';

const BackLoadingIndicator = ({ className, height, ...rest }) => {
  const classes = [styles.backLoadingIndicator, className];
  return (
    <div className={classes.join(' ')} style={{ height }} {...rest}>
      <LoadingIcon />
    </div>
  );
};

BackLoadingIndicator.defaultProps = {
  height: '300px',
};
BackLoadingIndicator.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
};

export default BackLoadingIndicator;