/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import 'react-tagsinput/react-tagsinput.css';
import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import { Button, IconButton } from 'components/buttons';
import { DateTimePicker, DropdownField, Form, InputField } from 'components/forms';
import { CloseIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import {
  isInProgress, isSuccessful
} from 'utils';

import { save } from './actions';
import { reducer } from './reducer';

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  location: yup.string().required('Required'),
  destinations: yup.string().required('Required'),
  questions: yup.array().of(yup.string().required('Cannot be empty')),
});


const VideoCampaignEditor = ({ className, locations, onClose, onSaved, ...rest }) => {
  const { useReducer } = React.useContext(AppContext);

  // first render
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  // form data
  const [{
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, {
    saveState: {},
  }, {
    formData: {},
    formErrors: {},
  }, 'video-campaign-editor');

  const onChange = useCallback(({ target: { name, value, checked, type } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({
      type: 'UPDATE_FORM_ITEM',
      path: name,
      value: type === 'checkbox' ? checked : value,
    });
  }, [formErrors, formData]);

  // save 
  const validateAndCallSave = useCallback(async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    dispatch(save(formData));
  }, [formData]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);

  useEffect(() => {
    if (!firstRender && isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  return (
    <Bloc className={[styles.videoCampaignEditor, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{'New Video Campaign'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <Form className={styles.form} error={_.get(formErrors, 'form')}>
        <InputField
          name='name'
          label="Campaign Name"
          placeholder="Enter campaign name"
          value={_.get(formData, 'name', '')}
          // readOnly
          error={_.get(formErrors, 'name', '')}
          onChange={onChange}
          onSubmit={validateAndCallSave}
          disabled={isInProgress(saveState)}
        />
        <DropdownField
          name='location'
          as="select"
          label='Location'
          placeholder='Select Location'
          value={_.get(formData, 'location', '')}
          error={_.get(formErrors, 'location', '')}
          onChange={onChange}
          disabled={isInProgress(saveState)}
          options={locations.map(location => ({ value: location.uid, name: location.name }))}
        />
        <DateTimePicker
          className={styles.dateTimePicker}
          name='schedule'
          label="Schedule"
          placeholder="Select Schedule"
          value={_.get(formData, 'schedule', '')}
          error={_.get(formErrors, 'schedule', '')}
          onChange={onChange}
          disabled={isInProgress(saveState)}
        />
        <InputField
          className={styles.destinations}
          as={'textarea'}
          name='destinations'
          label="Destinations"
          placeholder='Emails and/or Phone Numbers, new line separated'
          value={_.get(formData, 'destinations', '')}
          error={_.get(formErrors, 'destinations', '')}
          onChange={onChange}
          disabled={isInProgress(saveState)}>
        </InputField>
        {_.get(formData, 'questions', ['']).map((question, index) => (
          <InputField
            key={`question_${index}`}
            name={`questions.${index}`}
            label={`Question ${index + 1}`}
            placeholder="Enter Question"
            value={question}
            // readOnly
            error={
              <>
                <span>{_.get(formErrors, `questions[${index}]`)}</span>
                <span
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  hidden={index === 0}
                  onClick={() => {
                    const questions = _.get(formData, 'questions');
                    onChange({
                      target: {
                        name: 'questions',
                        value: questions.filter((_v, idx) => idx !== index),
                      }
                    });
                  }}
                >x remove</span>
              </>
            }
            onChange={onChange}
            onSubmit={validateAndCallSave}
            disabled={isInProgress(saveState)}
          />
        ))}
        <Button
          className={styles.addQuestionButton}
          text={'+ add question'}
          onClick={() => {
            const newQuestions = _.get(formData, 'questions', ['']).slice();
            newQuestions.push('');
            onChange({
              target: {
                name: 'questions',
                value: newQuestions,
              }
            });
          }}
          disabled={isInProgress(saveState)}
        />
        <Button
          className={styles.saveButton}
          text={'Send Campaign'}
          onClick={validateAndCallSave}
          loading={isInProgress(saveState)} />
      </Form >
    </Bloc >
  );
};

VideoCampaignEditor.defaultProps = {
};
VideoCampaignEditor.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default VideoCampaignEditor;
