/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from 'lodash';
import moment from 'moment';

import { ConfirmModal } from 'components/modals';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaCalendar, FaEye, FaReply } from 'react-icons/fa';
import { HiLocationMarker } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button } from 'components/buttons';
import { RemoveIcon } from 'components/icons';
import { AppContext } from 'containers';
import { AuthContext } from 'containers/auth/Login';
import { AsyncStateSelector, isEmpty, isFailed, isInProgress, isSuccessful } from 'utils';
import Header from '../Header';
import ListItem from '../ListItem';
import SearchResultMsg from '../SearchResultMsg';
import { deleteVideoCampaign, syncVideoCampaigns } from './actions';
import { initialState, reducer } from './reducer';
import styles from './styles.module.css';

const VideoCampaigns = ({ className, locations, onAdd }) => {
  const classes = [styles.container, className];

  // contexts
  const { claims } = useContext(AuthContext);
  const { __, firebase } = React.useContext(AppContext);
  const history = useHistory();

  // state
  const [itemToDelete, confirmItemDeletion] = useState(null);

  // api state
  const [{ syncVideoCampaignsState, deleteVideoCampaignState },
    dispatch] = useReducer(reducer, initialState);

  // effects
  useEffect(() => {
    if (isEmpty(syncVideoCampaignsState)) {
      syncVideoCampaigns(firebase, dispatch, claims);
    }
  }, [syncVideoCampaignsState]);

  useEffect(() => {
    if (isSuccessful(deleteVideoCampaignState) && itemToDelete != null)
      confirmItemDeletion(null);
  }, [deleteVideoCampaignState]);

  // search
  const [searchQuery, setSearchQuery] = useState('');
  const searchMode = useMemo(() => _.isEmpty(searchQuery) === false, [searchQuery]);

  return (
    <div className={classes.join(' ')}>
      <Header title='Video Reviews' onSearch={setSearchQuery}>
        <Button
          className={styles.addButton}
          text="+ New Campaign"
          onClick={onAdd} />
      </Header>
      <AsyncStateSelector
        state={syncVideoCampaignsState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load video campaigns</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onEmpty={() => (
          <BackPrint>
            <p className='title'>No Video Campaigns Yet</p>
          </BackPrint>
        )}
        onSuccess={(campaigns) => {
          if (searchMode) {
            const query = searchQuery.toUpperCase();
            campaigns = campaigns.filter(item =>
              item.name.toUpperCase().includes(query));
          }
          return (
            <>
              {searchMode &&
                <SearchResultMsg
                  text={`We found ${campaigns.length} results for "${searchQuery}"`} />
              }
              <div className={styles.list}>
                {!_.isEmpty(campaigns) &&
                  <Row className={styles.row}>
                    {campaigns.map(campaign => {
                      const location = locations.find(l => l.uid === campaign.location);
                      const locationName = location ? location.name : 'Unknown';
                      const replies = _.size(campaign.replies);
                      let unseen = 0;
                      _.values(campaign.replies || {}).forEach((reply) => {
                        reply.records.forEach(record => {
                          if (!record.seen)
                            unseen++;
                        });
                      });

                      return (
                        <Col key={campaign.uid} lg="3" md="4" sm="5">
                          <ListItem
                            onOpen={() => history.push(`/video-reviews/${campaign.uid}`)}
                            onDelete={() => confirmItemDeletion(campaign)} >
                            <div className='d-inline-flex'>
                              <div>
                                <p className='title'>{campaign.name}</p>
                                <p className='subtitle'>
                                  <HiLocationMarker style={{ marginTop: -3, marginLeft: -1, marginRight: 8 }} />
                                  {locationName}
                                </p>
                                <p className='subtitle'>
                                  <FaCalendar size={10} style={{ marginTop: -3, marginLeft: 1, marginRight: 10 }} />
                                  {moment(campaign.schedule).format('MMM DD YYYY, h:mm A')}
                                </p>
                                <p className='subtitle'>
                                  <FaReply size={10} style={{ marginTop: -2, marginLeft: 1, marginRight: 10 }} />
                                  {`${replies} ${replies === 1 ? 'reply' : 'replies'}`}
                                </p>
                                <p className='subtitle' style={{ color: unseen === 0 ? null : '#e9a173' }}>
                                  <FaEye size={12} style={{ marginTop: -2, marginLeft: 1, marginRight: 8 }} />
                                  {`${unseen} unseen testimonial${unseen === 1 ? '' : 's'}`}
                                </p>
                              </div>
                            </div>
                          </ListItem>
                        </Col>
                      );
                    })}
                  </Row>
                }
              </div>
            </>
          );
        }}
      />
      <ConfirmModal
        show={itemToDelete != null}
        onDismiss={() => {
          confirmItemDeletion(null);
          dispatch({ type: 'UPDATE_DELETE_STATE', value: {} });
        }}
        confirmButtonProps={{
          className: 'danger',
          icon: <RemoveIcon height={14} width={15} />,
          text: 'Remove',
          onClick: () => deleteVideoCampaign(firebase, dispatch, itemToDelete.uid),
          loading: isInProgress(deleteVideoCampaignState),
        }}>
        <span className='title'>Remove this item?</span>
        {isFailed(deleteVideoCampaignState) && <span className='error'>{deleteVideoCampaignState.error.message}</span>}
      </ConfirmModal>
    </div>
  );
};

VideoCampaigns.defaultProps = {
};
VideoCampaigns.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
};

export default VideoCampaigns;