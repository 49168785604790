/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from 'lodash';
import moment from 'moment';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Row } from 'react-bootstrap';
import { AiOutlineReload } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { Button } from 'components/buttons';
import {
  CloseIcon, RemoveIcon, ReplyIcon, ReviewReplierIcon, ReviewStarIcon,
} from 'components/icons';
import { ConfirmModal } from 'components/modals';
import { AppContext } from 'containers';
import { isFailed, isInProgress, isSuccessful, isUseless, success } from 'utils';
import { analizeReviewSentiment, deleteReply, submitReply, suggestReply } from './actions';
import { initialState, reducer } from './reducer';

import styles from './styles.module.css';
import { BackPrint } from 'components/back';

const ReviewListContainer = ({
  values,
  hasMore,
  isLoadingMore,
  onLoadMore,
  onReplySubmitted,
  onReplyDeleted,
}) => {
  // contexts
  const { firebase } = React.useContext(AppContext);

  // state
  const [{
    submitReplyState,
    deleteReplyState,
    analizeReviewSentimentState,
    suggestReplyState,
  }, dispatch] = useReducer(reducer, initialState);

  const [expandedComments, setExpandedComments] = useState([]);
  const [replyingToReviewIds, setReplyingReviewIds] = useState([]);
  const [replyingTexts, setReplyingTexts] = useState({});
  const [replyToDelete, confirmReplyDeletion] = useState(null);
  const [isConfirmingBulkReply, confirmBulkReply] = useState(false);

  // callbacks
  const callAnalizeReviewSentiment = useCallback((review) => {
    analizeReviewSentiment(firebase, dispatch, review);
  });

  // effects
  useEffect(() => {
    values.filter(v => v.replySuggestion).forEach((value) => {
      dispatch({
        type: 'UPDATE_SUGGEST_REPLY_STATE',
        id: value.review.reviewId,
        value: success(value.replySuggestion),
      });
    });
    setReplyingReviewIds(values.filter(v => v.replySuggestion).map(v => v.review.reviewId));
    const replyingSuggestions = {};
    values.filter(v => v.replySuggestion).forEach(v => {
      replyingSuggestions[v.review.reviewId] = v.replySuggestion;
    });
    setReplyingTexts(replyingSuggestions);
  }, [values]);

  useEffect(() => {
    Object.getOwnPropertyNames(suggestReplyState).forEach((reviewId) => {
      const state = submitReplyState[reviewId];
      if (isSuccessful(state)) {
        onReplySubmitted(state.value, state.value.comment);
        setReplyingReviewIds(replyingToReviewIds.filter(id => id !== reviewId));
        setReplyingTexts(_.omit(replyingTexts, state.value.reviewId));
        toast.info('Reply submitted', { autoClose: 1200 });
        dispatch({ type: 'UPDATE_SUBMIT_REPLY_STATE', id: reviewId, value: {} });
      } else if (isFailed(state)) {
        toast.error('Failed to submit reply. ' + (state.error.message || ''), { autoClose: 3200 });
        dispatch({ type: 'UPDATE_SUBMIT_REPLY_STATE', id: reviewId, value: {} });
      }
    });
  }, [submitReplyState]);

  useEffect(() => {
    if (replyToDelete && isSuccessful(deleteReplyState[replyToDelete.review.reviewId])) {
      onReplyDeleted(replyToDelete);
      confirmReplyDeletion(null);
      toast.info('Reply deleted', { autoClose: 2000 });
      dispatch({ type: 'UPDATE_DELETE_REPLY_STATE', id: replyToDelete.review.reviewId, value: {} });
    }
  }, [deleteReplyState, replyToDelete]);

  useEffect(() => {
    Object.getOwnPropertyNames(suggestReplyState).forEach((reviewId) => {
      if (isFailed(suggestReplyState[reviewId])) {
        toast.error('Failed to load reply suggestion. ' + (suggestReplyState[reviewId].error.message || ''), { autoClose: 3200 });
        dispatch({ type: 'UPDATE_SUGGEST_REPLY_STATE', id: reviewId, value: {} });
      }
    });
  }, [suggestReplyState]);

  useCallback(() => {
    for (const value of values) {
      if (value.review.comment && value.review.reviewReply == null) {
        const state = analizeReviewSentimentState[value.review.reviewId];
        if (isUseless(state))
          callAnalizeReviewSentiment(value.review);
      }
    }
  }, []);

  const callSuggestReply = useCallback((value) => {
    suggestReply(firebase, dispatch, value.location, value.review);
  }, []);

  return _.isEmpty(values) ? (
    <BackPrint>No reviews</BackPrint>
  ) : (
    <div className={styles.list}>
      <Row className={styles.row}>
        {values.map(value => {
          const { review, location } = value;
          const stars =
            ['STAR_RATING_UNSPECIFIED', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE']
              .indexOf(review.starRating);
          let comment = new String(review.comment || '');
          let collapsed = false;
          let onExpand = null;
          if (!expandedComments.includes(review.reviewId) && comment.length > 320) {
            comment = comment.substr(0, 300) + '...';
            collapsed = true;
            onExpand = () => setExpandedComments(expandedComments.concat(review.reviewId));
          }
          let sentiment, score, magnitude, replySuggestion;
          if (isSuccessful(suggestReplyState[review.reviewId])) {
            replySuggestion = suggestReplyState[review.reviewId].value;
          }
          return (
            <div key={`review_${review.reviewId}`}>
              <div>
                <img src={review.reviewer.profilePhotoUrl} alt="Reviewer's Photo" />
                <p>{review.reviewer.displayName}</p>
              </div>
              <div>
                <p>Review for <b>{location.name}</b>{stars <= 3 && <span className="alert">high-importance</span>}</p>
                <div className='stars'>
                  {_.range(0, 5).map(i => <ReviewStarIcon key={`star_${i}`} fill={Math.max(0, Math.min(1, stars - i))} />)}
                  <span>{moment.utc(review.updateTime).fromNow()}</span>
                </div>
                <p className={styles.comment}>{comment} {collapsed && <span onClick={onExpand}>Read More</span>}</p>
                {replyingToReviewIds.includes(review.reviewId)
                  ? (
                    <>
                      {sentiment &&
                        <img
                          className={styles.sentimentIcon}
                          src={`/images/${sentiment}_face.svg`}
                          title={score && magnitude
                            ? `Score: ${score}\nMagnitude: ${magnitude}`
                            : _.startCase(sentiment)}
                        />
                      }
                      <div className={styles.replyPanel}>
                        <div className={styles.reviewPanelWrapper}>
                          <ReviewReplierIcon width={40} height={40} />
                          <span>Admin</span>
                          <CloseIcon
                            className={styles.closeIcon}
                            color='#777777'
                            width={14}
                            height={14}
                            onClick={() => {
                              setReplyingReviewIds(replyingToReviewIds.filter(reviewId => reviewId !== review.reviewId));
                            }} />
                        </div>
                        {!_.isEmpty(replySuggestion)
                          ? function () {
                            return (
                              <div className={styles.suggestionBox}>
                                <div className='header'>
                                  <img src='/images/bot-white.png' width={30} />
                                  <span>Suggested Response from <b>ReviewX Bot</b></span>
                                </div>
                                {replySuggestion.split('\n').map((i, key) => <p key={key}>{i}</p>)}
                                <div className='footer'>
                                  <Button
                                    className={styles.suggestRefreshButton}
                                    icon={<AiOutlineReload />}
                                    loading={isInProgress(suggestReplyState[review.reviewId])}
                                    onClick={() => callSuggestReply(value)}
                                  />
                                  <Button
                                    text="Use this response"
                                    onClick={() => {
                                      setReplyingTexts(_.merge({}, replyingTexts, { [review.reviewId]: replySuggestion }));
                                      dispatch({ type: 'UPDATE_SUGGEST_REPLY_STATE', id: review.reviewId, value: {} });
                                    }} />
                                  <Button
                                    text="No, don't use"
                                    onClick={() => {
                                      dispatch({ type: 'UPDATE_SUGGEST_REPLY_STATE', id: review.reviewId, value: {} });
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }()
                          : (
                            <>
                              <textarea
                                name="reply"
                                placeholder='Type something to reply'
                                value={_.get(replyingTexts, review.reviewId)}
                                onChange={({ target }) => setReplyingTexts(_.merge({}, replyingTexts, { [review.reviewId]: target.value }))}
                              />
                              <div className={styles.replyButtons}>
                                <Button
                                  className={styles.suggestReplyButton}
                                  icon={<img src='/images/bot-white.png' width={23} />}
                                  loading={isInProgress(suggestReplyState[review.reviewId])}
                                  onClick={() => callSuggestReply(value)}
                                />
                                <Button
                                  className={styles.submitReplyButton}
                                  text='Reply'
                                  icon={<ReplyIcon />}
                                  loading={isInProgress(submitReplyState[review.reviewId])}
                                  disabled={isInProgress(submitReplyState[review.reviewId]) ||
                                    _.isEmpty(_.get(replyingTexts, review.reviewId)) ||
                                    _.get(replyingTexts, review.reviewId) === _.get(review, 'reviewReply.comment')}
                                  onClick={() => {
                                    submitReply(firebase, dispatch, location, review, _.get(replyingTexts, review.reviewId));
                                  }}
                                />
                              </div>
                            </>
                          )
                        }
                      </div>
                    </>
                  )
                  : review.reviewReply == null
                    ? <Button
                      className={styles.replyButton}
                      text='Reply'
                      icon={<ReplyIcon />}
                      onClick={() => {
                        setReplyingReviewIds([...replyingToReviewIds, review.reviewId]);
                        setReplyingTexts(_.get(review, 'reviewReply.comment'), '');
                        if (isUseless(suggestReplyState[review.reviewId]))
                          callSuggestReply(value);
                      }}
                    />
                    : <div className={styles.replyPanel}>
                      <div className={styles.reviewPanelWrapper}>
                        <ReviewReplierIcon width={40} height={40} />
                        <span>Admin</span>
                        <div className={styles.actions}>
                          <span onClick={() => {
                            setReplyingTexts(_.merge({}, replyingTexts, { [review.reviewId]: review.reviewReply.comment }));
                            setReplyingReviewIds([...replyingToReviewIds, review.reviewId]);
                          }}>Edit</span>
                          <span onClick={() => {
                            confirmReplyDeletion(value);
                          }}>Delete</span>
                        </div>
                      </div>
                      <p>{review.reviewReply.comment}</p>
                    </div>
                }
              </div>
            </div>
          );
        })}
      </Row>
      {hasMore || isLoadingMore ?
        <center>
          <Button
            className={styles.loadMoreButton}
            text='Load More'
            loading={isLoadingMore}
            onClick={onLoadMore}
          />
        </center> : null}
      {replyingToReviewIds.length > 1 ?
        <center>
          <Button
            className={styles.bulkReplyButton}
            icon={<ReplyIcon />}
            text={`Submit ${replyingToReviewIds.length} Suggesions`}
            loading={_.values(submitReplyState).find(state => isInProgress(state))}
            onClick={() => confirmBulkReply(true)}
          />
        </center> : null}
      <ConfirmModal
        show={replyToDelete != null}
        onDismiss={() => {
          confirmReplyDeletion(null);
          dispatch({ type: 'UPDATE_DELETE_REPLY_STATE', value: {} });
        }}
        confirmButtonProps={{
          className: 'danger',
          icon: <RemoveIcon height={14} width={15} />,
          text: 'Delete',
          onClick: () => deleteReply(firebase, dispatch, replyToDelete.location, replyToDelete.review),
          loading: isInProgress(replyToDelete && deleteReplyState[replyToDelete.review.reviewId]),
        }}>
        <span className='title'>Delete this reply?</span>
        {replyToDelete && isFailed(deleteReplyState[replyToDelete.review.reviewId]) &&
          <span className='error'>{deleteReplyState[replyToDelete.review.reviewId].error.message}</span>}
      </ConfirmModal>
      <ConfirmModal
        show={isConfirmingBulkReply}
        onDismiss={() => {
          confirmBulkReply(false);
        }}
        confirmButtonProps={{
          className: 'danger',
          text: 'Submit',
          onClick: () => {
            confirmBulkReply(false);
            replyingToReviewIds.forEach((reviewId, index) => {
              setTimeout(() => {
                const review = values.find(value => value.review.reviewId === reviewId);
                submitReply(firebase, dispatch, review.location, review.review, _.get(replyingTexts, review.review.reviewId));
              }, index * 100);
            });
          },
        }}>
        <span className='title'>{`Submit ${replyingToReviewIds.length} suggestions?`}</span>
        {replyToDelete && isFailed(deleteReplyState[replyToDelete.review.reviewId]) &&
          <span className='error'>{deleteReplyState[replyToDelete.review.reviewId].error.message}</span>}
      </ConfirmModal>
    </div>
  );
};

ReviewListContainer.defaultProps = {
  isLoadingMore: false,
};
ReviewListContainer.propTypes = {
  values: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  onReplySubmitted: PropTypes.func.isRequired,
  onReplyDeleted: PropTypes.func.isRequired,
};

export default ReviewListContainer;