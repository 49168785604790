/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import produce from 'immer';

export const initialState = {
  submitReplyState: {},
  deleteReplyState: {},
  analizeReviewSentimentState: {},
  suggestReplyState: {},
};

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_SUBMIT_REPLY_STATE': {
        draftState.submitReplyState[action.id] = action.value;
        break;
      }
      case 'UPDATE_DELETE_REPLY_STATE': {
        draftState.deleteReplyState[action.id] = action.value;
        break;
      }
      case 'UPDATE_ANALIZE_REVIEW_SENTIMENT_STATE': {
        draftState.analizeReviewSentimentState[action.id] = action.value;
        break;
      }
      case 'UPDATE_SUGGEST_REPLY_STATE': {
        draftState.suggestReplyState[action.id] = action.value;
        break;
      }
      default:
        return draftState;
    }
  });