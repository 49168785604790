/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import produce from 'immer';

import { isSuccessful, success } from 'utils';

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_LOAD_DATA_STATE': {
        draftState.loadState = action.value;
        break;
      }
      case 'UPDATE_LOAD_MORE_DATA_STATE': {
        draftState.loadMoreState = action.value;
        if (isSuccessful(state.loadState) && isSuccessful(action.value)) {
          draftState.loadState = success({
            data: [...state.loadState.value.data, ...action.value.value.data],
            nextPageId: action.value.value.nextPageId,
          });
        }
        break;
      }
      case 'UPDATE_CANCEL_LOG_CAMPAIGN_STATE': {
        draftState.cancelLogCampaignState = action.value;
        if (isSuccessful(state.loadState) && isSuccessful(action.value)) {
          draftState.loadState = success({
            data: state.loadState.value.data.map(i =>
              i.uid !== action.value.value ? i : { ...i, status: 'cancelled' }),
            nextPageId: action.value.value.nextPageId,
          });
        }
        break;
      }
      default:
        return draftState;
    }
  });