/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import produce from 'immer';
import * as _ from 'lodash';
import { isSuccessful, success } from 'utils';

export const initialState = {
  syncLocationsState: {},
  syncClientsState: {},
  syncDataFiltersState: {},
  syncBlacklistState: {},
  loadReviewsState: {},
  loadMoreReviewsState: {},
  syncPreRepliedReviewsState: {},
};

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_SYNC_LOCATIONS_STATE': {
        draftState.syncLocationsState = action.value;
        break;
      }
      case 'UPDATE_SYNC_CLIENTS_STATE': {
        draftState.syncClientsState = action.value;
        break;
      }
      case 'UPDATE_SYNC_DATAFILTERS_STATE': {
        draftState.syncDataFiltersState = action.value;
        break;
      }
      case 'UPDATE_SYNC_BLACKLIST_STATE': {
        draftState.syncBlacklistState = action.value;
        break;
      }
      case 'UPDATE_LOAD_REVIEWS_STATE': {
        draftState.loadReviewsState = action.value;
        break;
      }
      case 'UPDATE_REVIEW_REPLY': {
        if (isSuccessful(state.loadReviewsState)) {
          draftState.loadReviewsState = success({
            ...state.loadReviewsState.value,
            reviews: state.loadReviewsState.value.reviews.map(review => {
              if (review.reviewId !== action.review.reviewId)
                return review;
              return {
                ...review,
                reviewReply: {
                  comment: action.replyText,
                }
              };
            }),
          });
        }
        if (isSuccessful(state.syncPreRepliedReviewsState)) {
          draftState.syncPreRepliedReviewsState = success(
            state.syncPreRepliedReviewsState.value.filter(item =>
              item.review.reviewId !== action.review.reviewId));
        }
        break;
      }
      case 'UPDATE_SYNC_PRE_REPLIED_REVIEWS_STATE': {
        draftState.syncPreRepliedReviewsState = action.value;
        break;
      }
      case 'DELETE_REVIEW_REPLY': {
        if (isSuccessful(state.loadReviewsState)) {
          draftState.loadReviewsState = success({
            ...state.loadReviewsState.value,
            reviews: state.loadReviewsState.value.reviews.map(review => {
              if (review.reviewId !== action.review.reviewId)
                return review;
              return {
                ...review,
                reviewReply: null,
              };
            }),
          });
        }
        break;
      }
      case 'UPDATE_LOAD_MORE_REVIEWS_STATE': {
        draftState.loadMoreReviewsState = action.value;
        if (isSuccessful(action.value) && isSuccessful(state.loadReviewsState)) {
          const reviews = state.loadReviewsState.value.reviews.concat(action.value.value.reviews);
          draftState.loadReviewsState = success({
            reviews,
            nextPageToken: action.value.value.nextPageToken,
            averageRating: action.value.value.averageRating,
            totalReviewCount: action.value.value.totalReviewCount,
          });
        }
        break;
      }
      default:
        return draftState;
    }
  });