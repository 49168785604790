/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const ReviewStarIcon = ({ className, width, height, ...rest }) => (
  <svg className={[className].join(' ')} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.542 3.0075C19.7446 3.15482 19.8275 3.35125 19.7906 3.59679L17.4335 17.7396C17.4028 17.9177 17.3046 18.0558 17.1389 18.154C17.0529 18.2031 16.9578 18.2277 16.8534 18.2277C16.7859 18.2277 16.7122 18.2123 16.6324 18.1816L12.4614 16.4782L10.2332 19.1945C10.1227 19.3356 9.97227 19.4062 9.78198 19.4062C9.70218 19.4062 9.63466 19.3939 9.57942 19.3694C9.46279 19.3264 9.36918 19.2543 9.29858 19.153C9.22799 19.0517 9.1927 18.9397 9.1927 18.8169V15.6035L17.1481 5.85266L7.30515 14.3697L3.66815 12.8781C3.44103 12.7921 3.31827 12.6233 3.29985 12.3716C3.28757 12.1261 3.38579 11.945 3.59449 11.8284L18.9159 2.98912C19.008 2.93387 19.1062 2.90625 19.2105 2.90625C19.3333 2.90625 19.4438 2.94001 19.542 3.00753L19.542 3.0075Z" fill="white" />
  </svg>
);

ReviewStarIcon.defaultProps = {
  width: 22,
  height: 22,
};
ReviewStarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReviewStarIcon;