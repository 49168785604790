/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function load(firebase, dispatch) {
  dispatch({ type: 'UPDATE_LOAD_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('ai-prompts');
    ref.once('value', (snapshot) => {
      const data = snapshot.val();
      dispatch({ type: 'UPDATE_LOAD_STATE', value: success(data || {}) });
    });
  } catch (error) {
    dispatch({ type: 'UPDATE_LOAD_STATE', value: failed(error) });
  }
}

export async function save(firebase, dispatch, data) {
  dispatch({ type: 'UPDATE_SAVE_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('ai-prompts');
    await ref.set(data);
    dispatch({ type: 'UPDATE_SAVE_STATE', value: success(data) });
  } catch (error) {
    dispatch({ type: 'UPDATE_SAVE_STATE', value: failed(error) });
  }
}