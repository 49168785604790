/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function syncVideoCampaigns(firebase, dispatch) {
  dispatch({ type: 'UPDATE_SYNC_VIDEO_CAMPAIGNS_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('video_campaigns');
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      const items = _.keys(data).map(uid => ({ uid, ...data[uid] }));
      dispatch({ type: 'UPDATE_SYNC_VIDEO_CAMPAIGNS_STATE', value: success(items) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_VIDEO_CAMPAIGNS_STATE', value: failed(error) });
  }
}

export async function deleteVideoCampaign(firebase, dispatch, uid) {
  dispatch({ type: 'UPDATE_DELETE_STATE', value: inProgress() });
  try {
    await firebase.functions().httpsCallable('deleteVideoCampaign')(uid);
    dispatch({ type: 'UPDATE_DELETE_STATE', value: success(uid) });
  } catch (error) {
    dispatch({ type: 'UPDATE_DELETE_STATE', value: failed(error) });
  }
}

export async function markVideoRecordAsSeen(firebase, dispatch, campaign, reply, record) {
  dispatch({ type: 'UPDATE_MARK_VIDEO_RECORD_AS_SEEN_STATE', value: inProgress() });
  try {
    await firebase.functions().httpsCallable('markVideoRecordAsSeen')({campaign, reply, record});
    dispatch({ type: 'UPDATE_MARK_VIDEO_RECORD_AS_SEEN_STATE', value: success(campaign) });
  } catch (error) {
    dispatch({ type: 'UPDATE_MARK_VIDEO_RECORD_AS_SEEN_STATE', value: failed(error) });
  }
}