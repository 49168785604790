/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from 'lodash';

export function diffs(obj1, obj2) {
  return _.reduce(obj1, function (result, value, key) {
    if (!_.isEqual(value, _.get(obj2, key, {}))) {
      result[key] = value;
    }
    return result;
  }, _.reduce(obj2, function (result, value, key) {
    if (_.get(obj1, key) == null && _.get(obj2, key) != null)
      result[key] = null;
    return result;
  }, {}));
}