/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

const SearchResultMsg = ({ className, text, ...rest }) => {
  const classes = [styles.searchResult, className];
  return (
    <div className={classes.join(' ')} {...rest}>
      <span>{text}</span>
    </div>
  );
};

SearchResultMsg.defaultProps = {
};
SearchResultMsg.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default SearchResultMsg;