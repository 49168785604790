/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import moment from 'moment';

import { failed, inProgress, success } from 'utils';

export function loadData(claims, pageId, pageSize = 20) {
  return async function* call(firebase) {
    const actionType = pageId ? 'UPDATE_LOAD_MORE_DATA_STATE' : 'UPDATE_LOAD_DATA_STATE';
    yield { type: actionType, value: inProgress() };
    try {
      let ref = firebase.database().ref('historical-logs').orderByKey();
      if (pageId) ref = ref.endAt(pageId);
      ref = ref.limitToLast(pageSize + 1);
      yield new Promise(resolve => {
        ref.once('value', ($logs) => {
          let logsMap = $logs.val();
          if (_.isEmpty(logsMap)) {
            resolve({ type: actionType, value: success({ data: [], nextPageId: null }) });
            return;
          }
          let uids = _.keys(logsMap);
          let nextPageId;
          if (uids.length > pageSize) {
            nextPageId = uids.splice(0, 1)[0];
          }
          const data = [];
          for (const uid of uids) {
            const log = logsMap[uid];
            if (claims.locations && claims.locations.includes(log.location.uid) === false)
              continue;
            const datetime = log.initializeAt || log.createdAt;
            data.unshift({
              uid,
              date: moment(datetime).format('MM/DD/YYYY'),
              time: moment(datetime).format('hh:mm A'),
              ...log,
            });
          }
          resolve({ type: actionType, value: success({ data, nextPageId }) });
        });
      });
    } catch (error) {
      yield { type: actionType, value: failed(error) };
    }
  };
}

export function cancelLogCampaign(logUid) {
  return async function* call(firebase) {
    yield { type: 'UPDATE_CANCEL_LOG_CAMPAIGN_STATE', value: inProgress(logUid) };
    try {
      const ref = firebase.database().ref('historical-logs').child(logUid);
      await ref.update({ status: 'cancelled' });
      yield { type: 'UPDATE_CANCEL_LOG_CAMPAIGN_STATE', value: success(logUid) };
    } catch (e) {
      yield { type: 'UPDATE_CANCEL_LOG_CAMPAIGN_STATE', value: failed(e, logUid) };
    }
  };
}