/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, width, height, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox={`0 0 ${width} ${height}`} {...rest}>
    <path id="Icon_ionic-ios-information-circle" data-name="Icon ionic-ios-information-circle" d="M13.375,3.375a10,10,0,1,0,10,10A10,10,0,0,0,13.375,3.375Zm.913,15.187H12.452V11.634h1.837Zm-.918-8.254a.96.96,0,1,1,1-.962A.964.964,0,0,1,13.37,10.308Z" transform="translate(-3.375 -3.375)" fill="#202020" opacity="0.7" />
  </svg>
);

CloseIcon.defaultProps = {
  width: 20,
  height: 20,
};
CloseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CloseIcon;