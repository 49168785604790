/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import { failed, inProgress, success } from 'utils';


export function save(data, imageFile) {
  return async function* call(firebase, dispatch) {
    yield { type: 'UPDATE_SAVE_STATE', value: inProgress() };
    try {
      if (imageFile) {
        const backet = await firebase.storage();
        const ref = backet.ref(`location_images/${new Date().getTime()}/` + imageFile.name);
        const uploadTask = ref.put(imageFile);
        uploadTask.on('state_changed', (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('image upload is ' + progress + '% done');
        }, (error) => {
          console.error(error);
          dispatch({ type: 'UPDATE_SAVE_STATE', value: failed(error) });
        }, () => {
          console.log('image upload done');
          ref.getDownloadURL().then((image) => {
            dispatch(save({ ...data, image }));
          }).catch(error => {
            dispatch({ type: 'UPDATE_SAVE_STATE', value: failed(error) });
          });
        });
        return;
      }
      const res = await firebase.functions().httpsCallable('saveLocation')(data);
      if (res.data.status !== 'ok')
        yield { type: 'UPDATE_SAVE_STATE', value: failed(res) };
      else
        yield { type: 'UPDATE_SAVE_STATE', value: success(res) };
    } catch (error) {
      yield { type: 'UPDATE_SAVE_STATE', value: failed(error) };
    }
  };
}

export function connect() {
  return async function* call(firebase) {
    yield { type: 'UPDATE_CONNECT_STATE', value: inProgress() };
    try {
      const res = await firebase.functions().httpsCallable('connect')();
      yield { type: 'UPDATE_CONNECT_STATE', value: success(res.data) };
    } catch (error) {
      yield { type: 'UPDATE_CONNECT_STATE', value: failed(error) };
    }
  };
}

export function disconnect() {
  return async function* call(firebase) {
    yield { type: 'UPDATE_DISCONNECT_STATE', value: inProgress() };
    try {
      const res = await firebase.functions().httpsCallable('disconnect')();
      yield { type: 'UPDATE_DISCONNECT_STATE', value: success(res.data) };
    } catch (error) {
      yield { type: 'UPDATE_DISCONNECT_STATE', value: failed(error) };
    }
  };
}

export function findLocations() {
  return async function* call(firebase) {
    yield { type: 'UPDATE_LOCATIONS_STATE', value: inProgress() };
    try {
      const res = await firebase.functions().httpsCallable('getLocations')();
      yield { type: 'UPDATE_LOCATIONS_STATE', value: success(res.data) };
    } catch (error) {
      yield { type: 'UPDATE_LOCATIONS_STATE', value: failed(error) };
    }
  };
}

export function getLocation(uid) {
  return async function* call(firebase) {
    yield { type: 'UPDATE_GET_LOCATION_STATE', value: inProgress() };
    try {
      const res = await firebase.functions().httpsCallable('getLocation')(uid);
      yield { type: 'UPDATE_GET_LOCATION_STATE', value: success(res.data) };
    } catch (error) {
      yield { type: 'UPDATE_GET_LOCATION_STATE', value: failed(error) };
    }
  };
}