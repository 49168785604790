/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormInputField = ({ className, label, error, onSubmit, ...rest }) => (
  <div className={[styles.formInputField2, className].join(' ')}>
    <div className={styles.header}>
      <span className={styles.label}>{label}</span>
      <span className={styles.error}>{error}</span>
    </div>
    <Form.File
      className={styles.control}
      onKeyPress={event => {
        if (onSubmit && event.key === 'Enter') {
          onSubmit();
        }
      }}
      {...rest}
    />
  </div>
);

FormInputField.defaultProps = {
  disabled: false,
};
FormInputField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormInputField;