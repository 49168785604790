/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import produce from 'immer';

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_BULK_SEND_STATE': {
        draftState.submitState = action.value;
        break;
      }
      case 'UPDATE_FORM_DATA': {
        draftState.formData = action.value;
        break;
      }
      case 'UPDATE_FORM_ITEM': {
        _.set(draftState.formData, action.path.split('.'), action.value);
        break;
      }
      case 'UPDATE_FORM_ERROR': {
        _.set(draftState.formErrors, action.path.split('.'), action.value);
        break;
      }
      default:
        return draftState;
    }
  });