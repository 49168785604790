/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React, {
  createContext, useCallback, useContext, useEffect,
  useReducer, useState
} from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import _ from 'lodash';

import Header from 'components/panels/Header';
import { Button, IconButton } from 'components/buttons';
import HelpIcon from 'components/icons/Help';
import HideIcon from 'components/icons/Hide';
import LoadingIndicatorModal from 'components/modals/LoadingIndicator';
import Bloc from 'components/panels/Bloc';
import Form from 'components/forms/Form';
import FormInputField from 'components/forms/FormInputField';
import FormTitle from 'components/forms/FormTitle';
import FormSubtitle from 'components/forms/FormSubtitle';

import { AppContext } from 'containers';
import FormImage from './components/FormImage';

import { isInProgress } from 'utils';
import { login } from './actions';
import { initialState, reducer } from './reducer';

const LoginContainer = () => {
  const { __, firebase } = React.useContext(AppContext);
  const [{
    loginState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(loginState, 'error.message', ''),
    }, [loginState.error]);
  }, [loginState.error]);

  const validateAndLogin = useCallback(() => {
    const email = _.trim(formData.email);
    if (_.isEmpty(email))
      return dispatch({
        type: 'UPDATE_FORM_ERROR',
        path: 'email',
        value: 'Required',
      });
    if (_.isEmpty(formData.password))
      return dispatch({
        type: 'UPDATE_FORM_ERROR',
        path: 'password',
        value: 'Required',
      });

    // todo: validate email with yup
    login(firebase, dispatch, email, formData.password);
  }, [firebase, formData]);
  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  return (
    <LoadingIndicatorModal enabled={isInProgress(loginState)}>
      <div className={styles.loginConteiner}>
        <Header
          className={styles.header}
          buttons={<Button icon={<HelpIcon />} text="Help" />}
        />
        <Bloc className={styles.formBloc}>
          <FormImage />
          <FormTitle className={styles.formTitle} text="Welcome back!" />
          <FormSubtitle className={styles.formSubtitle} text="Please login to your account to continue" />
          <Form
            className={styles.form}
            error={_.get(formErrors, 'form')} >
            <FormInputField
              name='email'
              className={styles.formInputField}
              label="Enter email"
              type="email"
              value={_.get(formData, 'email', '')}
              error={_.get(formErrors, 'email', '')}
              onChange={onChange}
              onSubmit={validateAndLogin}
              disabled={isInProgress(loginState)}
            />
            <div>
              <FormInputField
                name='password'
                className={styles.formInputField + ' ' + styles.formPasswordField}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={_.get(formData, 'password', '')}
                error={_.get(formErrors, 'password', '')}
                onChange={onChange}
                onSubmit={validateAndLogin}
                disabled={isInProgress(loginState)}
              />
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <HideIcon className={styles.passwordHideIcon} />
              </IconButton>
            </div>
            <Button
              className={styles.button}
              text="Login"
              onClick={validateAndLogin}
              loading={isInProgress(loginState)} />
          </Form>
          <span className={styles.formCopyright}>© 2024 Copyright. All rights reserved.  Patient10x Reviews.</span>
        </Bloc>
      </div>
    </LoadingIndicatorModal>
  );
};

export const AuthContext = createContext();

function AuthRoute({ render }) {
  const { firebase } = useContext(AppContext);
  const [authState, setAuthState] = useState(null);
  useEffect(() => {
    if (authState == null) {
      firebase.auth().onAuthStateChanged(async function (user) {
        const auth = { user, claims: null };
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          const claims = idTokenResult.claims;
          if (!claims.admin && !claims.manager && !claims.reviewer) {
            console.error('invalid user %s tries to login, logging out', user.uid);
            await firebase.auth().signOut();
            return;
          }
          auth.claims = idTokenResult.claims;
        }
        setAuthState(auth);
      });
    }
  }, []);
  const logout = useCallback(() => {
    firebase.auth().signOut();
    window.open('/', '_self');
  }, []);

  if (!authState)
    return <LoadingIndicatorModal enabled={true} />;
  return <Route render={(props) => {
    if (!authState.user)
      return <LoginContainer />;
    return (
      <AuthContext.Provider value={{ ...authState, logout }}>
        {render(props)}
      </AuthContext.Provider>
    );
  }} />;
}

AuthRoute.defaultProps = {
};
AuthRoute.propTypes = {
  render: PropTypes.func.isRequired,
};

export default AuthRoute;