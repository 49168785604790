/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

const Bloc = ({ className, children, ...rest }) => (
  <div className={[styles.bloc, className].join(' ')} {...rest}>
    {children}
  </div>
);

Bloc.defaultProps = {
};
Bloc.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Bloc;