/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const ReportIcon = ({ className, active, width, height, ...rest }) => (
  <svg className={[className].join(' ')} width={width} height={height} viewBox="0 0 44 58" fill="none" xmlns="http://www.w3.org/2000/svg"  {...rest}>
    <defs>
      <linearGradient id="report_icon_lingrad" x1="29.4181" y1="24" x2="-3.80932" y2="23.0476" gradientUnits="userSpaceOnUse">
        <stop stopColor="#084EDD" />
        <stop offset="1" stopColor="#6CD0FF" />
      </linearGradient>
    </defs>
    <path d="M43.9743 3.65326V54.3533C43.9862 55.1308 43.6894 55.8814 43.1489 56.4406C42.6085 56.9998 41.8685 57.322 41.091 57.3366H3.5243C2.74678 57.322 2.00676 56.9998 1.46633 56.4406C0.925894 55.8814 0.629104 55.1308 0.640968 54.3533V3.65326C0.629099 2.87569 0.925887 2.12511 1.46632 1.56593C2.00676 1.00676 2.74678 0.684561 3.5243 0.669922H41.091C41.8685 0.684561 42.6085 1.00676 43.1489 1.56593C43.6894 2.12511 43.9862 2.87569 43.9743 3.65326Z" fill={active ? 'url(#paint0_linear)' : '#5E5F60'} />
    <path d="M36.526 14.0033H8.19271C7.97172 14.0032 7.7598 13.9154 7.60354 13.7591C7.44728 13.6028 7.35945 13.3909 7.35938 13.1699V11.5033C7.35945 11.2823 7.44728 11.0704 7.60354 10.9141C7.7598 10.7578 7.97172 10.67 8.19271 10.6699H36.526C36.747 10.67 36.9589 10.7578 37.1152 10.9141C37.2715 11.0704 37.3593 11.2823 37.3594 11.5033V13.1699C37.3593 13.3909 37.2715 13.6028 37.1152 13.7591C36.9589 13.9154 36.747 14.0032 36.526 14.0033Z" fill="#eee" />
    <path d="M33.1927 20.6732H8.19271C7.97172 20.6731 7.7598 20.5853 7.60354 20.429C7.44728 20.2728 7.35945 20.0608 7.35938 19.8398V18.1732C7.35945 17.9522 7.44728 17.7403 7.60354 17.584C7.7598 17.4277 7.97172 17.3399 8.19271 17.3398H33.1927C33.4137 17.3399 33.6256 17.4277 33.7819 17.584C33.9381 17.7403 34.026 17.9522 34.026 18.1732V19.8398C34.026 20.0608 33.9381 20.2728 33.7819 20.429C33.6256 20.5853 33.4137 20.6731 33.1927 20.6732Z" fill="#eee" />
    <path d="M36.526 27.3333H8.19271C7.97172 27.3333 7.7598 27.2454 7.60354 27.0892C7.44728 26.9329 7.35945 26.721 7.35938 26.5V24.8333C7.35945 24.6123 7.44728 24.4004 7.60354 24.2442C7.7598 24.0879 7.97172 24.0001 8.19271 24H36.526C36.747 24.0001 36.9589 24.0879 37.1152 24.2442C37.2715 24.4004 37.3593 24.6123 37.3594 24.8333V26.5C37.3593 26.721 37.2715 26.9329 37.1152 27.0892C36.9589 27.2454 36.747 27.3333 36.526 27.3333V27.3333Z" fill="#eee" />
    <path d="M36.526 40.6732H8.19271C7.97172 40.6731 7.7598 40.5853 7.60354 40.429C7.44728 40.2727 7.35945 40.0608 7.35938 39.8398V38.1732C7.35945 37.9522 7.44728 37.7403 7.60354 37.584C7.7598 37.4277 7.97172 37.3399 8.19271 37.3398H36.526C36.747 37.3399 36.9589 37.4277 37.1152 37.584C37.2715 37.7403 37.3593 37.9522 37.3594 38.1732V39.8398C37.3593 40.0608 37.2715 40.2727 37.1152 40.429C36.9589 40.5853 36.747 40.6731 36.526 40.6732V40.6732Z" fill="#eee" />
    <path d="M33.1927 34.0033H8.19271C7.97172 34.0032 7.7598 33.9154 7.60354 33.7591C7.44728 33.6028 7.35945 33.3909 7.35938 33.1699V31.5033C7.35945 31.2823 7.44728 31.0704 7.60354 30.9141C7.7598 30.7578 7.97172 30.67 8.19271 30.6699H33.1927C33.4137 30.67 33.6256 30.7578 33.7819 30.9141C33.9381 31.0704 34.026 31.2823 34.026 31.5033V33.1699C34.026 33.3909 33.9381 33.6028 33.7819 33.7591C33.6256 33.9154 33.4137 34.0032 33.1927 34.0033V34.0033Z" fill="#eee" />
    <path d="M33.1927 47.3333H8.19271C7.97172 47.3333 7.7598 47.2454 7.60354 47.0892C7.44728 46.9329 7.35945 46.721 7.35938 46.5V44.8333C7.35945 44.6123 7.44728 44.4004 7.60354 44.2442C7.7598 44.0879 7.97172 44.0001 8.19271 44H33.1927C33.4137 44.0001 33.6256 44.0879 33.7819 44.2442C33.9381 44.4004 34.026 44.6123 34.026 44.8333V46.5C34.026 46.721 33.9381 46.9329 33.7819 47.0892C33.6256 47.2454 33.4137 47.3333 33.1927 47.3333Z" fill="#eee" />
  </svg>
);

ReportIcon.defaultProps = {
  active: false,
  width: 44,
  height: 58,
};
ReportIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReportIcon;