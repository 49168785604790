/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import React, { useReducer, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { AppContext } from 'containers';
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful } from 'utils';
import { ConfirmModal } from 'components/modals';
import { BackLoadingIndicator, BackPrint } from 'components/back';
import { LocationIcon, RemoveIcon } from 'components/icons';

import ListItem from '../ListItem';
import SearchResultMsg from '../SearchResultMsg';
import Header from '../Header';

import { deleteLocation } from './actions';
import { initialState, reducer } from './reducer';
import { Button } from 'components/buttons';
import { AuthContext } from 'containers/auth/Login';

const LocationList = ({ className, syncLocationsState, onEdit, onAdd }) => {
  const classes = [styles.container, className];

  // contexts
  const { __, firebase } = React.useContext(AppContext);
  const { claims } = React.useContext(AuthContext);

  // state
  const [{ deleteState }, dispatch] = useReducer(reducer, initialState);
  const [searchQuery, setSearchQuery] = useState('');
  const [itemToDelete, confirmItemDeletion] = useState(null);

  // effects
  useEffect(() => {
    if (isSuccessful(deleteState) && itemToDelete != null)
      confirmItemDeletion(null);
  }, [deleteState]);

  const searchMode = useMemo(() =>
    _.isEmpty(searchQuery) === false, [searchQuery]);
  return (
    <div className={classes.join(' ')}>
      <Header
        title={claims.manager ? 'Blacklists' : 'Locations'}
        onSearch={setSearchQuery}
        searchFor="Location">
        {claims.admin && (
          <Button
            className={styles.addLocationButton}
            text="+ Add Location"
            disabled={isInProgress(syncLocationsState)}
            onClick={onAdd} />
        )}
      </Header>
      <AsyncStateSelector
        state={syncLocationsState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load locations</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={(locations) => {
          if (searchMode) {
            const query = searchQuery.toUpperCase();
            locations = locations.filter(item =>
              item.name.toUpperCase().includes(query) ||
              item.address.toUpperCase().includes(query));
          }
          return (
            <>
              {searchMode &&
                <SearchResultMsg
                  text={`We found ${locations.length} results for location "${searchQuery}"`} />
              }
              <div className={styles.list}>
                {!_.isEmpty(locations) &&
                  <Row className={styles.row}>
                    {locations.map(item => {
                      return (
                        <Col key={`location_${item.uid}`} lg="3" md="4" sm="5">
                          <ListItem
                            onEdit={() => onEdit(item)}
                            onDelete={() => confirmItemDeletion(item)}>
                            <p className='d-flex'>
                              <LocationIcon className='icon' />
                              <p className='title'>{item.name}</p>
                            </p>
                            <p className='subtitle'>{item.address}</p>
                          </ListItem>
                        </Col>
                      );
                    })}
                  </Row>
                }
                <ConfirmModal
                  show={itemToDelete != null}
                  onDismiss={() => {
                    confirmItemDeletion(null);
                    dispatch({ type: 'UPDATE_DELETE_STATE', value: {} });
                  }}
                  confirmButtonProps={{
                    className: 'danger',
                    icon: <RemoveIcon height={14} width={15} />,
                    text: 'Remove',
                    onClick: () => deleteLocation(firebase, dispatch, itemToDelete.uid),
                    loading: isInProgress(deleteState),
                  }}>
                  <span className='title'>Remove this item?</span>
                  {isFailed(deleteState) && <span className='error'>{deleteState.error.message}</span>}
                </ConfirmModal>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

LocationList.defaultProps = {
};
LocationList.propTypes = {
  className: PropTypes.string,
  syncLocationsState: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
};

export default LocationList;