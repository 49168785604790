/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import React, { } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { SelectField } from 'components/forms';
import { ReviewStarIcon } from 'components/icons';

const ReviewFilters = ({
  locations,
  selectedLocation,
  onSelectedLocationChanged,
  showReplied,
  onShowRepliedChanged,
  selectedStars,
  onSelectedStarsChanged,
  maxStars,
}) => {
  return (
    <div className={styles.container}>
      <SelectField
        className={styles.locationsDropdown}
        name='location'
        label='Location'
        placeholder='Select location'
        value={_.get(selectedLocation, 'uid', '')}
        onChange={({ value }) => onSelectedLocationChanged(locations.find(l => l.uid === value))}
        options={locations.map(location => ({ value: location.uid, label: location.name }))}
      />
      {showReplied !== undefined &&
        <SelectField
          className={styles.showRepliedDropdown}
          label='Replied'
          placeholder='Select type of repiled'
          value={showReplied}
          onChange={({ value }) => onShowRepliedChanged(value)}
          options={[
            { value: 'all', label: 'All Reviews' },
            { value: 'yes', label: 'Replied' },
            { value: 'no', label: 'Haven\'t Replied' },
          ]}
        />
      }
      <SelectField
        className={styles.selectedStars}
        label='Rating'
        placeholder='Select Rating'
        value={selectedStars}
        onChange={(values) => onSelectedStarsChanged(values.map(i => i.value))}
        options={[
          { value: 'ONE', label: '1 Star' },
          { value: 'TWO', label: '2 Star' },
          { value: 'THREE', label: '3 Star' },
          { value: 'FOUR', label: '4 Star' },
          { value: 'FIVE', label: '5 Star' },
        ].slice(0, maxStars)}
        isMulti={true}
        components={{
          Control: ({ children, ...props }) => {
            const values = props.getValue();
            return (
              <components.Control {...props}>
                {!_.isEmpty(values) && <ReviewStarIcon />}
                {children}
              </components.Control>
            );
          },
        }}
      />
    </div>
  );
};

ReviewFilters.defaultProps = {
  maxStars: 5,
};
ReviewFilters.propTypes = {
  locations: PropTypes.array.isRequired,
  selectedLocation: PropTypes.object,
  onSelectedLocationChanged: PropTypes.func.isRequired,
  showReplied: PropTypes.string,
  onShowRepliedChanged: PropTypes.func,
  selectedStars: PropTypes.array.isRequired,
  onSelectedStarsChanged: PropTypes.func.isRequired,
  maxStars: PropTypes.number,
};

export default ReviewFilters;