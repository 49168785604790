/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = ({ className, width, height, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 20.337 20.337" {...rest}>
    <path id="fi-rr-copy" d="M12.523,16.7H4.174A4.179,4.179,0,0,1,0,12.523V4.174A4.179,4.179,0,0,1,4.174,0h8.349A4.179,4.179,0,0,1,16.7,4.174v8.349A4.179,4.179,0,0,1,12.523,16.7ZM4.174,1.67a2.5,2.5,0,0,0-2.5,2.5v8.349a2.5,2.5,0,0,0,2.5,2.5h8.349a2.5,2.5,0,0,0,2.5-2.5V4.174a2.5,2.5,0,0,0-2.5-2.5ZM20.037,15.863V5.009a.835.835,0,1,0-1.67,0V15.863a2.5,2.5,0,0,1-2.5,2.5H5.009a.835.835,0,1,0,0,1.67H15.863A4.179,4.179,0,0,0,20.037,15.863Z" transform="translate(0.15 0.15)" fill="#084edd" stroke="#f6f6f6" strokeWidth="0.3" />
  </svg>
);

CopyIcon.defaultProps = {
  width: 20,
  height: 20,
};
CopyIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CopyIcon;