/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const ReviewIcon = ({ className, active, width, height, ...rest }) => (
  
  <svg className={[className].join(' ')} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="url(#pattern0)" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill={active ? 'url(#paint0_linear)' : '#5E5F60'} />
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0" transform="scale(0.0208333)" />
      </pattern>
      <linearGradient id="paint0_linear" x1="29.4181" y1="24" x2="-3.80932" y2="23.0476" gradientUnits="userSpaceOnUse">
        <stop stopColor="#084EDD" />
        <stop offset="1" stopColor="#6CD0FF" />
      </linearGradient>
      <image id="image0" width="48" height="48" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZS07DMBRFD5+2FKX7YQ5lOR1RPkWiomspm4ApiB3AgAoV1pGoDOxIJcQvbuLUlXhHeoM6/tyb2s9xAoqyExwCY2ABZMDKEZmtc2Hb7AQHwBNu0a54tG2jc83m4vMYR9D7h3d+C+oIdTvA5Vr9t9bVWY6BGWb+prjvaOLRVyK0T+0Y90A/pPhXYdD1uAK6Ql9d/KfcSygTM88B24hpCAOLiAY+qsTteRhIiZfuMir2Cx8DqzBaaiNq3N+WirZQA7H59wYmmJ31tqS8h1mAUvRK2gZHytP5o8PAUe5DsW0xRJr+AyOM2FFJufRIkdMtabsRug/ERg3EpomBL+AMs4iHwHcQRS3gSm+nhXrDtWs3+GehiTBGkATie3wcCNckou0DJ8Lvre0DPrjuzBIzbRLgHLMm2jiViehGFhs1EBs9D9g6eh6oi6bR2PgYyFpX0WBsHwPLAELq8llVwcfAQwAhdZmH6KSP+diw7Vfrz8BRCAO5iSnmfb30ialppHaMu5DiFUXZYX4AmkQ/Q/fomesAAAAASUVORK5CYII=" />
    </defs>
  </svg>
);

ReviewIcon.defaultProps = {
  active: false,
  width: 19,
  height: 19,
};
ReviewIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReviewIcon;