/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React, {
  useCallback, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';

import { AppContext } from 'containers';
import Form from 'components/forms/Form';
import FormInputField from 'components/forms/FormInputField';
import Bloc from 'components/panels/Bloc';
import { Button, IconButton } from 'components/buttons';
import { CloseIcon } from 'components/icons';
import { BackLoadingIndicator, BackPrint } from 'components/back';
import { AsyncStateSelector, isInProgress, isSuccessful } from 'utils';

import { load, save } from './actions';
import { initialState, reducer } from './reducer';

const formSchema = yup.object().shape({
  locationScreenLegalInfo: yup.string().required('Required'),
  locationScreenSubtitle: yup.string().required('Required'),
  locationScreenTitle: yup.string().required('Required'),
  successfulScreenEmailTitle: yup.string().required('Required'),
  successfulScreenSmsTitle: yup.string().required('Required'),
  successfulScreenSubTitle: yup.string().required('Required'),
  smsBody: yup.string().required('Required'),
  emailTitle: yup.string().required('Required'),
  emailBody: yup.string().required('Required'),
  emailButton: yup.string().required('Required'),
});

const TabletTextsEditor = ({ className, onClose, onSaved, ...rest }) => {
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [{
    loadState,
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);

  // state effects
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);
  useEffect(() => {
    if (isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);
  useEffect(() => {
    load(firebase, dispatch);
  }, []);
  useEffect(() => {
    if (isSuccessful(loadState))
      dispatch({ type: 'UPDATE_FORM_DATA', value: loadState.value });
  }, [loadState.value]);

  //  callbacks
  const validateAndSave = useCallback(async () => {
    try {
      await formSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    save(firebase, dispatch, formData);
  }, [formData]);
  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  return (
    <Bloc className={[styles.tabletTextsEditor, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{'Tablet Texts'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <AsyncStateSelector
        state={loadState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load clients</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={() => (
          <Form
            className={styles.form}
            error={_.get(formErrors, 'form')} >
            <FormInputField
              name='locationScreenTitle'
              label="Location Screen Title"
              value={_.get(formData, 'locationScreenTitle', '')}
              error={_.get(formErrors, 'locationScreenTitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              as="textarea"
              rows={2}
              name='locationScreenSubtitle'
              label="Location Screen Subtitle"
              value={_.get(formData, 'locationScreenSubtitle', '')}
              error={_.get(formErrors, 'locationScreenSubtitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              as="textarea"
              rows={4}
              name='locationScreenLegalInfo'
              label="Location Screen Legal Info"
              value={_.get(formData, 'locationScreenLegalInfo', '')}
              error={_.get(formErrors, 'locationScreenLegalInfo', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='successfulScreenEmailTitle'
              label="Successful Screen Email Title"
              value={_.get(formData, 'successfulScreenEmailTitle', '')}
              error={_.get(formErrors, 'successfulScreenEmailTitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='successfulScreenSmsTitle'
              label="Successful Screen SMS Title"
              value={_.get(formData, 'successfulScreenSmsTitle', '')}
              error={_.get(formErrors, 'successfulScreenSmsTitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              as="textarea"
              rows={2}
              name='successfulScreenSubTitle'
              label="Successful Screen Subtitle"
              value={_.get(formData, 'successfulScreenSubTitle', '')}
              error={_.get(formErrors, 'successfulScreenSubTitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='smsBody'
              label="SMS Body"
              value={_.get(formData, 'smsBody', '')}
              error={_.get(formErrors, 'smsBody', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='emailTitle'
              label="Email Title"
              value={_.get(formData, 'emailTitle', '')}
              error={_.get(formErrors, 'emailTitle', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='emailBody'
              label="Email Body"
              value={_.get(formData, 'emailBody', '')}
              error={_.get(formErrors, 'emailBody', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <FormInputField
              name='emailButton'
              label="Email Button"
              value={_.get(formData, 'emailButton', '')}
              error={_.get(formErrors, 'emailButton', '')}
              onChange={onChange}
              onSubmit={validateAndSave}
              disabled={isInProgress(saveState)}
            />
            <Button
              className={styles.saveButton}
              text={'Save Changes'}
              onClick={validateAndSave}
              loading={isInProgress(saveState)} />
          </Form>
        )}
      />
    </Bloc>
  );
};

TabletTextsEditor.defaultProps = {
};
TabletTextsEditor.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default TabletTextsEditor;