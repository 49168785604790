/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = ({ className, ...rest }) => {
  const classes = [styles.loadingIndicator, className];
  return (
    <img className={classes.join(' ')} alt='loading' src='/images/logo-icon.png' {...rest} />
  );
};

LoadingIcon.defaultProps = {
};
LoadingIcon.propTypes = {
  className: PropTypes.string,
};

export default LoadingIcon;