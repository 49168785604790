/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';
import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { AppContext } from 'containers';
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful } from 'utils';
import { ConfirmModal } from 'components/modals';
import { BackLoadingIndicator, BackPrint } from 'components/back';
import { ClientIcon, RemoveIcon } from 'components/icons';
import { Button } from 'components/buttons';

import ListItem from '../ListItem';
import SearchResultMsg from '../SearchResultMsg';
import Header from '../Header';

import { deleteClient } from './actions';
import { initialState, reducer } from './reducer';

const ClientListContainer = ({ className, syncClientsState, onEdit, onAdd }) => {
  const classes = [styles.container, className];
  // contexts
  const { __, firebase } = React.useContext(AppContext);
  // state
  const [{ deleteState }, dispatch] = useReducer(reducer, initialState);
  const [itemToDelete, confirmItemDeletion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // effects
  useEffect(() => {
    if (isSuccessful(deleteState) && itemToDelete != null)
      confirmItemDeletion(null);
  }, [deleteState]);
 
  const searchMode = _.isEmpty(searchQuery) === false;
  return (
    <div className={classes.join(' ')}>
      <Header title='Clients' onSearch={setSearchQuery}>
        <Button
          className={styles.addClientButton}
          text="+ Add Client"
          onClick={onAdd} />
      </Header>
      <AsyncStateSelector
        state={syncClientsState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load clients</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={(clients) => {
          if (searchMode) {
            const query = searchQuery.toUpperCase();
            clients = clients.filter(item =>
              item.name.toUpperCase().includes(query));
          }
          return (
            <>
              {searchMode &&
                <SearchResultMsg
                  text={`We found ${clients.length} results for client "${searchQuery}"`} />
              }
              <div className={styles.list}>
                {!_.isEmpty(clients) &&
                  <Row className={styles.row}>
                    {clients.map(item => {
                      return (
                        <Col key={`client_${item.uid}`} lg="3" md="4" sm="5">
                          <ListItem
                            onEdit={() => onEdit(item)}
                            onDelete={() => confirmItemDeletion(item)}>
                            <div className='d-flex'>
                              <ClientIcon className='icon' />
                              <p className='title'>{item.name}</p>
                            </div>
                          </ListItem>
                        </Col>
                      );
                    })}
                  </Row>
                }
                <ConfirmModal
                  show={itemToDelete != null}
                  onDismiss={() => confirmItemDeletion(null)}
                  confirmButtonProps={{
                    className: 'danger',
                    icon: <RemoveIcon height={14} width={15} />,
                    text: 'Remove',
                    onClick: () => deleteClient(firebase, dispatch, itemToDelete.uid),
                    loading: isInProgress(deleteState),
                  }}
                >
                  <span className='title'>Remove this item?</span>
                  {isFailed(deleteState) && <span className='error'>{deleteState.error.message}</span>}
                </ConfirmModal>
              </div>
            </>
          );
        }}
      />
    </div >
  );
};

ClientListContainer.defaultProps = {
};
ClientListContainer.propTypes = {
  className: PropTypes.string,
  syncClientsState: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default ClientListContainer;