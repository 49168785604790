/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';

const FormInputField = ({ className, label, error, ...rest }) => {
  const classes = React.useMemo(() => {
    const classes = [styles.tagsinput, className];
    return classes.join(' ');
  }, [className]);

  return (
    <div className={classes}>
      <div className={styles.header}>
        <span className={styles.label}>{label}</span>
        <span className={styles.error}>{error}</span>
      </div>
      <TagsInput
        {...rest}
      />
    </div>
  );
};

FormInputField.defaultProps = {
  disabled: false,
};
FormInputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormInputField;