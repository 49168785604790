/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function submitReply(firebase, dispatch, location, review, replyText) {
  dispatch({
    type: 'UPDATE_SUBMIT_REPLY_STATE',
    id: review.reviewId,
    value: inProgress(review)
  });
  try {
    const args = { location: location.uid, review: review.name, reply: replyText };
    const res = await firebase.functions().httpsCallable('submitReply')(args);
    dispatch({
      type: 'UPDATE_SUBMIT_REPLY_STATE',
      id: review.reviewId,
      value: success(_.merge({}, review, res.data.data)),
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_SUBMIT_REPLY_STATE',
      id: review.reviewId,
      value: failed(error, review),
    });
  }
}

export async function deleteReply(firebase, dispatch, location, review) {
  dispatch({
    type: 'UPDATE_DELETE_REPLY_STATE',
    id: review.reviewId,
    value: inProgress(),
  });
  try {
    const args = { location: location.uid, review: review.name };
    const res = await firebase.functions().httpsCallable('deleteReply')(args);
    dispatch({
      type: 'UPDATE_DELETE_REPLY_STATE',
      id: review.reviewId,
      value: success(res.data.data),
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_DELETE_REPLY_STATE',
      id: review.reviewId,
      value: failed(error),
    });
  }
}

export async function analizeReviewSentiment(firebase, dispatch, review) {
  dispatch({
    type: 'UPDATE_ANALIZE_REVIEW_SENTIMENT_STATE',
    id: review.reviewId,
    value: inProgress(),
  });
  try {
    const args = { comment: review.comment };
    const res = await firebase.functions().httpsCallable('analizeSentiment')(args);
    dispatch({
      type: 'UPDATE_ANALIZE_REVIEW_SENTIMENT_STATE',
      id: review.reviewId,
      value: success(res.data.entity),
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_ANALIZE_REVIEW_SENTIMENT_STATE',
      id: review.reviewId,
      value: failed(error),
    });
  }
}

export async function suggestReply(firebase, dispatch, location, review) {
  dispatch({
    type: 'UPDATE_SUGGEST_REPLY_STATE',
    id: review.reviewId,
    value: inProgress(),
  });
  try {
    const args = { review, client: location.client };
    const res = await firebase.functions().httpsCallable('suggestReply')(args);
    dispatch({
      type: 'UPDATE_SUGGEST_REPLY_STATE',
      id: review.reviewId,
      value: success(res.data.value),
    });
  } catch (error) {
    dispatch({
      type: 'UPDATE_SUGGEST_REPLY_STATE',
      id: review.reviewId,
      value: failed(error),
    });
  }
}