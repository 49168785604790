/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const LocationsIcon = ({ className, active, width, height, ...rest }) => (
  <svg className={[className].join(' ')} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 17.25" {...rest}>
    <g>
      <linearGradient id="linear-gradient" x1="0.738" y1="0.816" x2="-0.07" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#084edd" />
        <stop offset="1" stopColor="#489ff9" />
      </linearGradient>
      <path fill={active ? 'url(#linear-gradient)' : '#5E5F60'} d="M0,13.467C0,11.256,2.994,10.7,6.5,10.7c3.525,0,6.5.573,6.5,2.782S10.006,16.25,6.5,16.25C2.975,16.25,0,15.677,0,13.467ZM2.2,4.3A4.3,4.3,0,1,1,6.5,8.6,4.285,4.285,0,0,1,2.2,4.3Z" />
    </g>
  </svg>
);

LocationsIcon.defaultProps = {
  active: false,
  width: 14,
  height: 17.25,
};
LocationsIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LocationsIcon;