/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

import SearchBar from '../SearchBar';

const Header = ({ className, title, searchFor, onSearch, children, ...rest }) => {
  const [searchText, setSearchText] = React.useState('');
  const classes = [styles.header, className];
  return (
    <div className={classes.join(' ')} {...rest}>
      <div className={styles.title}>{title}</div>
      {onSearch &&
        <SearchBar
          value={searchText}
          searchFor={searchFor || title}
          onChange={({ target: { value } }) => setSearchText(value)}
          onClear={() => {
            setSearchText('');
            onSearch(null);
          }}
          onSearch={() => onSearch(searchText)}
        />
      }
      {children}
    </div >
  );
};

Header.defaultProps = {
};
Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  searchFor: PropTypes.string,
  onSearch: PropTypes.func,
  children: PropTypes.node,
};

export default Header;