/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import { failed, inProgress, success } from 'utils';

export function save(data) {
  return async function* call(firebase) {
    yield { type: 'UPDATE_SAVE_STATE', value: inProgress() };
    try {
      const res = await firebase.functions().httpsCallable('saveVideoCampaign')(data);
      if (res.data.status !== 'ok')
        yield { type: 'UPDATE_SAVE_STATE', value: failed(res) };
      else
        yield { type: 'UPDATE_SAVE_STATE', value: success(res) };
    } catch (error) {
      yield { type: 'UPDATE_SAVE_STATE', value: failed(error) };
    }
  };
}