/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ className, children, onClick, ...rest }) => (
  <div className={[styles.iconButton, className].join(' ')} onClick={onClick} {...rest}>
    {children}
  </div>
);

IconButton.defaultProps = {
};
IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconButton;