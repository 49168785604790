/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import produce from 'immer';

export const initialState = {
  getAccountsState: {},
  deleteAccountState: {},
};

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_GET_ACCOUNTS_STATE': {
        draftState.getAccountsState = action.value;
        break;
      }
      case 'UPDATE_DELETE_ACCOUNT_STATE': {
        draftState.deleteAccountState = action.value;
        break;
      }
      default:
        return draftState;
    }
  });