/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ className, width, height, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox='0 0 15.5 15.5' {...rest}>
    <g transform="translate(0.006 0.112)">
      <path d="M0,.5H5.641" transform="translate(9.109 14.092)" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M7.612.624A1.723,1.723,0,0,1,10.149.489l1.23.964a1.721,1.721,0,0,1,.474,2.5L4.52,13.3a1.31,1.31,0,0,1-1.017.5l-2.828.036-.64-2.756A1.315,1.315,0,0,1,.279,9.979Z" transform="translate(0.75 0.75)" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M0,0,4.241,3.324" transform="translate(6.989 3.126)" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
    </g>
  </svg>
);

EditIcon.defaultProps = {
  width: 15.5,
  height: 15.5,
};
EditIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default EditIcon;