/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

import PropTypes from 'prop-types';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button } from 'components/buttons';
import { AppContext } from 'containers';
import { AuthContext } from 'containers/auth/Login';
import { AsyncStateSelector, isInProgress, isSuccessful } from 'utils';

import AiPromptEditor from './components/AiPromptEditor';
import BlacklistEditor from './components/BlacklistEditor';
import BulkSendForm from './components/BulkSendForm';
import ClientEditor from './components/ClientEditor';
import ClientList from './components/ClientList';
import DataFilterEditor from './components/DataFilterEditor';
import DataFilterList from './components/DataFilterList';
import FeedbackRatingList from './components/FeedbackRatings';
import Header from './components/Header';
import HistoricalLog from './components/HistoricalLog';
import LocationEditor from './components/LocationEditor';
import LocationList from './components/LocationList';
import LocationReviewStatsList from './components/LocationReviewStatsList';
import NegativeReviewsBlockedList from './components/NegativeReviews';
import ReviewFilters from './components/ReviewFilters';
import ReviewList from './components/ReviewList';
import SearchResultMsg from './components/SearchResultMsg';
import Sidebar from './components/Sidebar';
import TabletTextsEditor from './components/TabletTextsEditor';
import Topbar from './components/Topbar';
import VideoCampaignEditor from './components/VideoCampaignEditor';
import VideoCampaigns from './components/VideoCampaigns';
import VideoCampaignViewer from './components/VideoCampaignViewer';

import {
  loadMoreReviews, loadReviews, syncBlacklist, syncClients,
  syncDataFilters, syncLocations, syncPreRepliedReviews,
} from './actions';
import { initialState, reducer } from './reducer';

import AccountList from './components/AccountList';
import styles from './styles.module.css';

const HomeContainer = ({ history }) => {
  const { firebase } = useContext(AppContext);
  const { logout, claims } = useContext(AuthContext);
  const queryParams = new URLSearchParams(useLocation().search);

  // state
  const [searchQuery, setSearchQuery] = useState('');
  const [editingClient, setEditingClient] = useState(null);
  const [editingDataFilter, setEditingDataFilter] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const [isTabletTextsEditorShown, showTabletTextsEditor] = useState(false);
  const [isAiPromptEditorShown, showAiPromptEditor] = useState(false);
  const [isBlacklistEditorShown, showBlacklistEditor] = useState(false);
  const [isBulkSendFormShown, showBulkSendForm] = useState(false);
  const [reviewLocation, setReviewLocation] = useState(null);
  const [showRepliedReviews, setShowRepliedReviews] = useState(null);
  const [reviewStars, setReviewStars] = useState([]);
  const [negativeReviewLocation, setNegativeReviewLocation] = useState(null);
  const [negativeReviewStars, setNegativeReviewStars] = useState([]);
  const [editingVideoCampaign, setEditingVideoCampaign] = useState(null);

  const [{
    syncLocationsState,
    syncClientsState,
    syncDataFiltersState,
    syncBlacklistState,
    loadReviewsState,
    loadMoreReviewsState,
    syncPreRepliedReviewsState,
  }, dispatch] = useReducer(reducer, initialState);

  // vars
  const isAdmin = _.get(claims, 'admin') === true;
  const isManager = _.get(claims, 'manager') === true;
  const isReviewer = _.get(claims, 'reviewer') === true;

  // effects
  useEffect(() => {
    let cancelSyncLocations, cancelSyncClients;
    let cancelSyncDataFilters, cancelSyncBlacklist;
    let cancelSyncPreRepliedReviews;
    syncLocations(firebase, dispatch, claims).then(cancel => {
      cancelSyncLocations = cancel;
    });
    syncPreRepliedReviews(firebase, dispatch).then(cancel => {
      cancelSyncPreRepliedReviews = cancel;
    });
    if (isAdmin) {
      syncClients(firebase, dispatch).then(cancel => {
        cancelSyncClients = cancel;
      });
    }
    if (isAdmin || isManager) {
      syncDataFilters(firebase, dispatch).then(cancel => {
        cancelSyncDataFilters = cancel;
      });
      syncBlacklist(firebase, dispatch).then(cancel => {
        cancelSyncBlacklist = cancel;
      });
    }
    return () => {
      if (cancelSyncLocations) cancelSyncLocations();
      if (cancelSyncClients) cancelSyncClients();
      if (cancelSyncDataFilters) cancelSyncDataFilters();
      if (cancelSyncBlacklist) cancelSyncBlacklist();
      if (cancelSyncPreRepliedReviews) cancelSyncPreRepliedReviews();
    };
  }, []);
  useEffect(() => {
    if (isSuccessful(syncClientsState) && queryParams.get('gauth') === 'success') {
      setEditingLocation({});
      queryParams.delete('gauth');
      history.replace({ search: queryParams.toString() });
    }
  }, [syncClientsState.status]);
  useEffect(() => {
    if (reviewLocation) {
      loadReviews(firebase, dispatch, reviewLocation);
    }
  }, [reviewLocation]);

  const searchMode = _.isEmpty(searchQuery) === false;
  return (
    <>
      <div className={styles.container}>
        <Router>
          <Topbar
            onBulkSendPressed={!isAdmin && !isManager ? null : () => showBulkSendForm(true)}
            onTabletTextsPressed={!isAdmin ? null : () => showTabletTextsEditor(true)}
            onAiPromptEditorPressed={!isAdmin ? null : () => showAiPromptEditor(true)}
            onBlacklistPressed={!isAdmin ? null : () => showBlacklistEditor(true)}
            onLogout={logout}
          />
          <div className={styles.page}>
            <Sidebar />
            <Switch>
              {(isAdmin) && <Route exact path="/(|locations)/" render={() =>
                <LocationList
                  className={styles.content}
                  syncLocationsState={syncLocationsState}
                  onEdit={setEditingLocation}
                  onAdd={() => setEditingLocation({})}
                />
              } />}
              {(isManager) && <Route exact path="/(|blacklists)/" render={() =>
                <LocationList
                  className={styles.content}
                  syncLocationsState={syncLocationsState}
                  onEdit={setEditingLocation}
                  onAdd={() => setEditingLocation({})}
                />
              } />}
              {isAdmin && <Route path="/clients" render={() =>
                <ClientList
                  className={styles.content}
                  syncClientsState={syncClientsState}
                  onEdit={setEditingClient}
                  onAdd={() => setEditingClient({})}
                />
              } />}
              {isAdmin && <Route path="/data-filters" render={() =>
                <DataFilterList
                  className={styles.content}
                  syncDataFiltersState={syncDataFiltersState}
                  locations={syncLocationsState.value || []}
                  blacklist={syncBlacklistState.value || {}}
                  onEdit={setEditingDataFilter}
                  onAdd={() => setEditingDataFilter({})}
                  onBulkCampaignData={showBulkSendForm}
                />
              } />}
              <Route
                exact={isReviewer || isManager}
                path={isReviewer || isManager ? '/(|reviews)/' : '/reviews'}
                render={() => {
                  let reviews = [];
                  if (isSuccessful(loadReviewsState)) {
                    reviews = loadReviewsState.value.reviews || [];
                    if (searchMode) {
                      const query = searchQuery.toUpperCase();
                      reviews = reviews.filter(review =>
                        review.reviewer.displayName.toUpperCase().includes(query) ||
                        (review.comment || '').toUpperCase().includes(query));
                    }
                    if (reviewStars.length > 0) {
                      reviews = reviews.filter(r => reviewStars.includes(r.starRating));
                    }
                    if (showRepliedReviews === 'yes') {
                      reviews = reviews.filter(r => r.reviewReply != null);
                    } else if (showRepliedReviews === 'no') {
                      reviews = reviews.filter(r => r.reviewReply == null);
                    }
                  }

                  return (
                    <div className={styles.content}>
                      <Header
                        title='Reviews'
                        onSearch={(filter) => setSearchQuery(filter)}
                      />
                      <div className={styles.reviews}>
                        {isSuccessful(loadReviewsState) &&
                          <div className={styles.totalReviewsStats}>
                            <h1>{reviews.length} Reviews</h1>
                            {_.isEmpty(reviews) ? null :
                              <h3>There is <b>{loadReviewsState.value.totalReviewCount}</b> reviews total. Average rating is <b>{loadReviewsState.value.averageRating}</b></h3>}
                          </div>}
                        <ReviewFilters
                          locations={(syncLocationsState.value || []).filter(l => l.ref != null)}
                          selectedLocation={reviewLocation}
                          onSelectedLocationChanged={setReviewLocation}
                          showReplied={showRepliedReviews}
                          onShowRepliedChanged={setShowRepliedReviews}
                          selectedStars={reviewStars}
                          onSelectedStarsChanged={setReviewStars}
                        />
                        {reviewLocation == null && syncLocationsState.value ? (
                          <AsyncStateSelector
                            state={syncPreRepliedReviewsState}
                            onProgress={() => <BackLoadingIndicator />}
                            onFail={(error) => (
                              <BackPrint>
                                <p className='title'>Failed to load approval que</p>
                                <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
                              </BackPrint>
                            )}
                            onSuccess={(value) => {
                              const preRepliedReviews = value.map(v => ({
                                review: v.review,
                                replySuggestion: v.replySuggestion,
                                location: syncLocationsState.value.find(l => l.uid === v.location),
                              })).filter(v => v.location != null);
                              return (
                                <>
                                  {searchMode &&
                                    <SearchResultMsg
                                      text={`We found ${reviews.length} results for "${searchQuery}"`} />
                                  }
                                  <ReviewList
                                    values={preRepliedReviews}
                                    hasMore={false}
                                    isLoadingMore={false}
                                    onLoadMore={() => null}
                                    onReplySubmitted={(review, replyText) => {
                                      dispatch({ type: 'UPDATE_REVIEW_REPLY', review, replyText });
                                    }}
                                    onReplyDeleted={(review) => {
                                      dispatch({ type: 'DELETE_REVIEW_REPLY', review });
                                    }}
                                  />
                                </>
                              );
                            }}
                          />
                        ) : (
                          <AsyncStateSelector
                            state={loadReviewsState}
                            onProgress={() => <BackLoadingIndicator />}
                            onFail={(error) => (
                              <BackPrint>
                                <p className='title'>Failed to load reviews</p>
                                <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
                                <Button text='Try Again'
                                  onClick={() => loadReviews(firebase, dispatch, reviewLocation)} />
                              </BackPrint>
                            )}
                            onSuccess={({ nextPageToken, totalReviewCount }) => {
                              return (
                                <>
                                  {searchMode &&
                                    <SearchResultMsg
                                      text={`We found ${reviews.length} results for "${searchQuery}"`} />
                                  }
                                  <ReviewList
                                    values={reviews.map(review => {
                                      const preRepledReview = (syncPreRepliedReviewsState.value || []).find(v => v.review.reviewId === review.reviewId);
                                      return {
                                        review,
                                        location: reviewLocation,
                                        replySuggestion: preRepledReview?.replySuggestion,
                                      };
                                    })}
                                    hasMore={nextPageToken != null && totalReviewCount > reviews.length}
                                    isLoadingMore={isInProgress(loadMoreReviewsState)}
                                    onLoadMore={() => loadMoreReviews(firebase, dispatch, reviewLocation, nextPageToken)}
                                    onReplySubmitted={(review, replyText) => {
                                      dispatch({ type: 'UPDATE_REVIEW_REPLY', review, replyText });
                                    }}
                                    onReplyDeleted={(review) => {
                                      dispatch({ type: 'DELETE_REVIEW_REPLY', review });
                                    }}
                                  />
                                </>
                              );
                            }}
                          />)
                        }
                      </div>
                    </div>
                  );
                }} />
              <Route
                path={'/video-reviews/:uid'}
                render={(props) => (
                  <VideoCampaignViewer
                    className={styles.content}
                    // eslint-disable-next-line react/prop-types
                    uid={props.match.params.uid}
                    locations={syncLocationsState.value || []}
                  />
                )}
              />
              <Route
                path={isReviewer || isManager ? '/(|video-reviews)/' : '/video-reviews'}
                render={(props) => (
                  <VideoCampaigns
                    className={styles.content}
                    locations={syncLocationsState.value || []}
                    onAdd={() => setEditingVideoCampaign({})}
                    {...props}
                  />
                )}
              />
              <Route
                exact={isReviewer || isManager}
                path={isReviewer || isManager ? '/(|review-stats)/' : '/review-stats'}
                render={(props) => {
                  return (
                    <AsyncStateSelector
                      state={syncLocationsState}
                      onProgress={() => <BackLoadingIndicator />}
                      onFail={(error) => (
                        <BackPrint>
                          <p className='title'>Failed to load review stats</p>
                          <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
                        </BackPrint>
                      )}
                      onSuccess={() => {
                        return (
                          <div className={styles.content}>
                            <LocationReviewStatsList
                              locations={(syncLocationsState.value || []).filter(l => l.ref != null)}
                              onClick={(location) => {
                                props.history.push('/reviews');
                                setReviewLocation(location);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
              />
              <Route
                exact={isReviewer || isManager}
                path={isReviewer || isManager ? '/(|feedback-ratings)/' : '/feedback-ratings'}
                render={(props) => {
                  return (
                    <AsyncStateSelector
                      state={syncLocationsState}
                      onProgress={() => <BackLoadingIndicator />}
                      onFail={(error) => (
                        <BackPrint>
                          <p className='title'>Failed to load feedback ratings</p>
                          <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
                        </BackPrint>
                      )}
                      onSuccess={() => {
                        return (
                          <div className={styles.content}>
                            <Header title='Feedback Ratings' />
                            <FeedbackRatingList
                              locations={(syncLocationsState.value || []).filter(l => l.ref != null)}
                              onClick={(location) => {
                                props.history.push('/reviews');
                                setReviewLocation(location);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
              />
              <Route
                path={'/negative-reviews'}
                render={() => (
                  <div className={styles.content}>
                    <Header
                      title='Negative Feedback'
                      onSearch={(filter) => setSearchQuery(filter)}
                    />
                    {!searchMode &&
                      <ReviewFilters
                        locations={(syncLocationsState.value || []).filter(l => l.ref != null)}
                        maxStars={3}
                        selectedLocation={negativeReviewLocation}
                        onSelectedLocationChanged={setNegativeReviewLocation}
                        selectedStars={negativeReviewStars}
                        onSelectedStarsChanged={setNegativeReviewStars}
                      />
                    }
                    {negativeReviewLocation &&
                      <NegativeReviewsBlockedList
                        location={negativeReviewLocation}
                        searchMode={searchMode}
                        searchQuery={searchQuery}
                        ratings={negativeReviewStars}
                      />
                    }
                  </div>
                )} />
              {isAdmin || isManager ? <Route path="/historical-log" render={() =>
                <HistoricalLog
                  className={styles.content}
                  searchMode={searchMode}
                  searchQuery={searchQuery}
                />
              } /> : null}
              {isAdmin && <Route path="/accounts" render={() =>
                <AccountList
                  className={styles.content}
                  locations={syncLocationsState.value}
                />
              } />}
              <Route path="*">
                <div className={styles.notFoundContainer}>
                  <span>404 - Page not found</span>
                </div>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
      <Modal
        centered
        backdrop="static"
        show={editingVideoCampaign != null}
        onHide={() => setEditingVideoCampaign(null)}>
        {editingVideoCampaign && <VideoCampaignEditor
          locations={syncLocationsState.value}
          onClose={() => setEditingVideoCampaign(null)}
          onSaved={(_) => setEditingVideoCampaign(null)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={editingLocation != null}
        onHide={() => setEditingLocation(null)}>
        {editingLocation && <LocationEditor
          location={editingLocation}
          clients={syncClientsState.value}
          onClose={() => setEditingLocation(null)}
          onSaved={(_) => setEditingLocation(null)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={editingClient != null}
        onHide={() => setEditingClient(null)}>
        {editingClient && <ClientEditor
          client={editingClient}
          onClose={() => setEditingClient(null)}
          onSaved={(_) => setEditingClient(null)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={editingDataFilter != null}
        onHide={() => setEditingDataFilter(null)}>
        {editingDataFilter && <DataFilterEditor
          data={editingDataFilter}
          locations={syncLocationsState.value}
          onClose={() => setEditingDataFilter(null)}
          onSaved={(_) => setEditingDataFilter(null)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={isTabletTextsEditorShown}
        onHide={() => showTabletTextsEditor(false)}>
        {isTabletTextsEditorShown && <TabletTextsEditor
          onClose={() => showTabletTextsEditor(false)}
          onSaved={(_) => showTabletTextsEditor(false)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={isAiPromptEditorShown}
        onHide={() => showAiPromptEditor(false)}>
        {isAiPromptEditorShown && <AiPromptEditor
          onClose={() => showAiPromptEditor(false)}
          onSaved={(_) => showAiPromptEditor(false)}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={isBlacklistEditorShown}
        onHide={() => showBlacklistEditor(false)}>
        {isBlacklistEditorShown && <BlacklistEditor
          onClose={() => showBlacklistEditor(false)}
          onSaved={(_) => showBlacklistEditor(false)}
          syncState={syncBlacklistState}
        />}
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={
          isBulkSendFormShown &&
          isSuccessful(syncLocationsState) &&
          isSuccessful(syncDataFiltersState) &&
          isSuccessful(syncBlacklistState)
        }
        onHide={() => showBulkSendForm(false)}>
        {isBulkSendFormShown && <BulkSendForm
          onClose={() => showBulkSendForm(false)}
          locations={syncLocationsState.value}
          blacklist={syncBlacklistState.value}
          dataFilters={syncDataFiltersState.value}
          data={isBulkSendFormShown === true ? null : isBulkSendFormShown}
        />}
      </Modal>
    </>
  );
};

HomeContainer.propTypes = {
  history: PropTypes.any,
};
export default HomeContainer;