/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTabletAlt, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { BsShieldFillExclamation } from 'react-icons/bs';

import { Header } from 'components/panels';
import { Button } from 'components/buttons';
import { PowerIcon } from 'components/icons';

const Topbar = ({
  className,
  onBulkSendPressed,
  onTabletTextsPressed,
  onAiPromptEditorPressed,
  onBlacklistPressed,
  onLogout,
  ...rest
}) => {
  const classes = [styles.container, className];
  return (
    <Header
      className={classes.join(' ')}
      buttons={
        <>
          {onBulkSendPressed &&
            <Button
              className={styles.topbarAction}
              icon={<FontAwesomeIcon icon={faMailBulk} />}
              onClick={onBulkSendPressed}
            />}
          {onBlacklistPressed &&
            <Button
              className={styles.topbarAction}
              icon={<BsShieldFillExclamation size="16px" />}
              onClick={onBlacklistPressed}
            />}
          {onAiPromptEditorPressed &&
            <Button
              className={styles.topbarAction}
              icon={<img src='/images/bot-black.png' width={23} />}
              onClick={onAiPromptEditorPressed}
            />}
          {onTabletTextsPressed &&
            <Button
              className={styles.topbarAction}
              icon={<FontAwesomeIcon icon={faTabletAlt} />}
              onClick={onTabletTextsPressed}
            />}
          <Button
            className={styles.logoutButton}
            icon={<PowerIcon />}
            text="Logout"
            onClick={onLogout}
          />
        </>
      }
      {...rest}
    />
  );
};

Topbar.defaultProps = {
  addingLocationDisabled: false,
};
Topbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  onBulkSendPressed: PropTypes.func,
  onTabletTextsPressed: PropTypes.func,
  onAiPromptEditorPressed: PropTypes.func,
  onBlacklistPressed: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
};

export default withRouter(Topbar);