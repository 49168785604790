/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export function GetFeedbackRatingsAction(location) {
  return async function* (firebase) {
    const locationId = location.uid;
    yield { type: 'UPDATE_GET_FEEDBACK_RATINGS_STATE', locationId, value: inProgress() };
    try {
      const args = { location: locationId };
      const { data } = await firebase.functions().httpsCallable('getFeedbackRatings')(args);
      if (data.status === 'ok')
        yield { type: 'UPDATE_GET_FEEDBACK_RATINGS_STATE', locationId, value: success(data) };
      else
        yield { type: 'UPDATE_GET_FEEDBACK_RATINGS_STATE', locationId, value: failed(data) };
    } catch (error) {
      yield { type: 'UPDATE_GET_FEEDBACK_RATINGS_STATE', locationId, value: failed(error) };
    }
  };
}