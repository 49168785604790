/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const PowerIcon = ({ className }) => (
  <svg className={className} width="19" height="19" viewBox="0 0 19 19">
    <g id="Icon_ionic-ios-power" data-name="Icon ionic-ios-power" transform="translate(-4.5 -4.5)">
      <path id="Path_6" data-name="Path 6" d="M20.155,7.764a.9.9,0,0,0-.584-.213.9.9,0,0,0-.9.975.9.9,0,0,0,.317.614A7.669,7.669,0,1,1,6.306,14.949,7.6,7.6,0,0,1,9.017,9.14a.9.9,0,0,0,.1-1.272.9.9,0,0,0-1.267-.1A9.39,9.39,0,0,0,4.5,14.944,9.5,9.5,0,1,0,20.155,7.764Z" transform="translate(0 -0.904)" fill="#202020" />
      <path id="Path_7" data-name="Path 7" d="M17.678,14.915A.916.916,0,0,0,18.593,14V5.415a.915.915,0,0,0-1.831,0V14A.916.916,0,0,0,17.678,14.915Z" transform="translate(-3.633 0)" fill="#202020" />
    </g>
  </svg>
);

PowerIcon.defaultProps = {
};
PowerIcon.propTypes = {
  className: PropTypes.string,
};

export default PowerIcon;