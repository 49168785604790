/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import PropTypes from 'prop-types';
import _ from 'lodash';

export function failed(err, value = undefined) {
  if (_.get(err, 'response.status') === 403) {
    // auth.clearAppStorage();
  }
  return {
    status: 'failed',
    error: {
      code: _.get(err, 'message',
        _.get(err, 'response.data.code', 'server-error')),
      message: _.get(err, 'message',
        _.get(err, 'response.data.message', 'Unable to process request')),
    },
    value,
  };
}

export function inProgress(value = undefined) {
  return {
    status: 'in-progress',
    value,
  };
}

export function success(value = {}) {
  return {
    status: 'success',
    value,
  };
}

export function empty(value = {}) {
  return {
    status: 'empty',
    value,
  };
}

export const isInProgress = (state) => state && state.status === 'in-progress';
export const isSuccessful = (state) => state && state.status === 'success';
export const isNotSuccessful = (state) => !isSuccessful(state);
export const isFailed = (state) => state && state.status === 'failed';
export const isEmpty = (state) => _.isEmpty(state);
export const isUseless = (state) => isEmpty(state) || isFailed(state);
export const isComplete = (state) => isSuccessful(state) || isFailed(state);
export const isNotComplete = (state) => !isComplete(state);

export const AsyncStateSelector = ({ state, onEmpty, onNotEmpty, onValue, onSuccess, onProgress, onFail, orElse }) => {
  if (state) {
    if (onEmpty != null && state.value != null && _.isEmpty(state.value)) return onEmpty();
    if (onNotEmpty != null && state.value != null && _.isEmpty(state.value) === false) return onNotEmpty(state.value);
    if (onValue != null && state.value != null) return onValue(state.value);
    if (onSuccess != null && isSuccessful(state)) return onSuccess(state.value);
    if (onProgress != null && isInProgress(state)) return onProgress();
    if (onFail != null && isFailed(state)) return onFail(state.error);
  }
  if (orElse != null) return orElse();
  return null;
};

AsyncStateSelector.defaultProps = {
};
AsyncStateSelector.propTypes = {
  state: PropTypes.object.isRequired,
  onEmpty: PropTypes.func,
  onNotEmpty: PropTypes.func,
  onValue: PropTypes.func,
  onSuccess: PropTypes.func,
  onProgress: PropTypes.func,
  onFail: PropTypes.func,
  orElse: PropTypes.func,
};