/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { Badge, Col, Modal, Row } from 'react-bootstrap';
import { ImUser } from 'react-icons/im';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button } from 'components/buttons';
import { RemoveIcon } from 'components/icons';
import { ConfirmModal } from 'components/modals';
import { AppContext } from 'containers';
import { AsyncStateSelector, isEmpty, isFailed, isInProgress, isSuccessful } from 'utils';
import AccountEditor from '../AccountEditor';
import Header from '../Header';
import ListItem from '../ListItem';
import SearchResultMsg from '../SearchResultMsg';
import { deleteAccount, getAccounts } from './actions';
import { initialState, reducer } from './reducer';
import styles from './styles.module.css';


const AccountList = ({ className, locations }) => {
  const classes = [styles.container, className];
  // contexts
  const { __, firebase } = React.useContext(AppContext);
  // state
  const [{ getAccountsState, deleteAccountState }, dispatch] = useReducer(reducer, initialState);
  const [itemToDelete, confirmItemDeletion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingAccount, setEditingAccount] = useState(null);

  // effects
  useEffect(() => {
    if (isEmpty(getAccountsState))
      getAccounts(firebase, dispatch);
  }, [getAccountsState]);

  useEffect(() => {
    if (isSuccessful(deleteAccountState) && itemToDelete != null) {
      confirmItemDeletion(null);
      getAccounts(firebase, dispatch);
    }
  }, [deleteAccountState]);

  return (
    <div className={classes.join(' ')}>
      <Header title='Accounts' onSearch={setSearchQuery}>
        <Button
          className={styles.addButton}
          text="+ Add"
          onClick={() => {
            setEditingAccount({});
          }} />
      </Header>
      <AsyncStateSelector
        state={getAccountsState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load accounts</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
            <Button text='Try Again' onClick={() => getAccounts(firebase, dispatch)} />
          </BackPrint>
        )}
        onSuccess={(accounts) => {
          if (searchQuery && searchQuery.length) {
            const query = searchQuery.toUpperCase();
            accounts = accounts.filter(item =>
              (item.displayName && item.displayName.toUpperCase().includes(query)) ||
              item.uid.toUpperCase().includes(query) ||
              item.email.toUpperCase().includes(query));
          }
          return (
            <>
              {searchQuery && searchQuery.length &&
                <SearchResultMsg
                  text={`We found ${accounts.length} results for account "${searchQuery}"`} />
              }
              <div className={styles.list}>
                {!_.isEmpty(accounts) &&
                  <Row className={styles.row}>
                    {accounts.map(account => {
                      const accountRole = account.customClaims == null ? 'account'
                        : account.customClaims.admin ? 'admin'
                          : account.customClaims.manager ? 'manager'
                            : 'reviewer';
                      return (
                        <Col key={`account_${account.uid}`} lg="3" md="4" sm="5">
                          <ListItem
                            onEdit={() => setEditingAccount(account)}
                            onDelete={() => confirmItemDeletion(account)}>
                            <div className='d-flex'>
                              <ImUser className='icon' />
                              <p className='title'>{account.displayName}</p>
                            </div>
                            <p className='subtitle'>
                              {account.email}
                            </p>
                            {accountRole !== 'account' && (
                              <Badge
                                style={{ marginTop: 5 }}
                                variant={accountRole === 'admin' ? 'danger' : 'primary'} >
                                {accountRole}
                              </Badge>)}
                          </ListItem>
                        </Col>
                      );
                    })}
                  </Row>
                }
              </div>
            </>
          );
        }}
      />
      <ConfirmModal
        show={itemToDelete != null}
        onDismiss={() => confirmItemDeletion(null)}
        confirmButtonProps={{
          className: 'danger',
          icon: <RemoveIcon height={14} width={15} />,
          text: 'Remove',
          onClick: () => deleteAccount(firebase, dispatch, itemToDelete.uid),
          loading: isInProgress(deleteAccountState),
        }}
      >
        <span className='title'>Remove this account?</span>
        {isFailed(deleteAccountState) && <span className='error'>{deleteAccountState.error.message}</span>}
      </ConfirmModal>
      <Modal
        centered
        backdrop="static"
        show={editingAccount != null}
        onHide={() => setEditingAccount(null)}>
        {editingAccount && <AccountEditor
          account={editingAccount}
          locations={locations}
          onClose={() => setEditingAccount(null)}
          onSaved={(_) => {
            setEditingAccount(null);
            getAccounts(firebase, dispatch);
          }}
        />}
      </Modal>
    </div >
  );
};

AccountList.defaultProps = {
};
AccountList.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array.isRequired,
};

export default AccountList;