/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { FaEye } from 'react-icons/fa';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { AppContext } from 'containers';
import { AsyncStateSelector, isEmpty } from 'utils';
import Header from '../Header';
import { markVideoRecordAsSeen, syncVideoCampaigns } from './actions';
import { initialState, reducer } from './reducer';

const VideoCampaignViewer = ({ className, uid, locations }) => {
  const classes = [styles.container, className];

  // contexts
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [selectedReply, setSelectedReply] = useState(null);

  // api state
  const [{ syncVideoCampaignsState }, dispatch] = useReducer(reducer, initialState);

  // effects
  useEffect(() => {
    if (isEmpty(syncVideoCampaignsState)) {
      syncVideoCampaigns(firebase, dispatch);
    }
  }, [syncVideoCampaignsState]);

  // search
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className={classes.join(' ')}>
      <AsyncStateSelector
        state={syncVideoCampaignsState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load video campaigns</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onEmpty={() => (
          <BackPrint>
            <p className='title'>404 - Not Found</p>
          </BackPrint>
        )}
        onSuccess={(campaigns) => {
          const campaign = campaigns.find(c => c.uid === uid);
          if (!campaign) {
            return (
              <BackPrint>
                <p className='title'>404 - Not Found</p>
              </BackPrint>
            );
          }
          const location = locations.find(l => l.uid === campaign.location);
          if (!location) {
            return (
              <BackPrint>
                <p className='title'>404 - Location Not Found</p>
              </BackPrint>
            );
          }

          return (
            <>
              <Header title={`Video Feedback for ${location.name}`} />
              <div className={styles.body}>
                <div className={styles.leftPanel}>
                  <input
                    className={styles.searchInput}
                    type='search'
                    value={searchQuery}
                    placeholder='Search...'
                    onChange={({ target: { value } }) => setSearchQuery(value)}
                  />
                  {_.isEmpty(campaign.replies) ? (
                    <BackPrint>
                      <p className='title'>No Replies Yet</p>
                    </BackPrint>
                  ) : _.entries(campaign.replies)
                    .filter(([_replyUid, reply]) => {
                      if (searchQuery.length === 0) return true;
                      const query = searchQuery.toLowerCase();
                      return reply.user.name.toLowerCase().includes(query) ||
                        reply.user.email.toLowerCase().includes(query);
                    })
                    .map(([replyUid, reply], replyIndex) => (
                      <div key={`reply_${replyIndex}`}
                        className={[
                          styles.reply,
                          selectedReply && replyUid === selectedReply.uid ? styles.selectedReply : '',
                          reply.records.find(record => !record.seen) == null ? styles.seenReply : '',
                        ].join(' ')}
                        onClick={() => {
                          if (selectedReply && selectedReply.uid === replyUid) {
                            setSelectedReply(null);
                          } else {
                            setSelectedReply({ uid: replyUid, ...reply });
                          }
                        }}>
                        <div>
                          <span className='title'>{reply.user.name}</span>
                          <span className='subtitle'>{reply.user.email}</span>
                        </div>
                        <span>{moment(reply.createdAt).format('MMM D').toUpperCase()}</span>
                      </div>
                    ))}
                </div>
                <div className={[styles.rightPanel, selectedReply ? 'selected' : ''].join(' ')}>
                  {selectedReply && (
                    <>
                      <h3>{selectedReply.user.name}</h3>
                      <h4>{selectedReply.user.email}</h4>
                      {selectedReply.records.map((record, recordIndex) => (
                        <div key={`record_${recordIndex}`} className={styles.question}>
                          <h3>Question {recordIndex + 1} {record.seen && <FaEye size={14} style={{ marginTop: -2, marginLeft: 3 }} color='#bbb' />}</h3>
                          <p>{campaign.questions[recordIndex]}</p>
                          <div className={styles.record}>
                            <img
                              src={record.assets.thumbnail}
                              onError={({ currentTarget }) => {
                                currentTarget.src = '/images/loading_image.png';
                                setTimeout(() => {
                                  currentTarget.src = record.assets.thumbnail;
                                }, 1000);
                              }}
                            />
                            <a className='preview'
                              rel="noreferrer"
                              target='_blank'
                              href={record.assets.player}
                              onClick={() => dispatch(markVideoRecordAsSeen(firebase, dispatch,
                                campaign.uid, selectedReply.uid, recordIndex))}>
                              Preview
                              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.99917 1.00016C3.99917 1.3735 4.29917 1.66683 4.66583 1.66683H8.3925L1.1325 8.92683C0.8725 9.18683 0.8725 9.60683 1.1325 9.86683C1.3925 10.1268 1.8125 10.1268 2.0725 9.86683L9.3325 2.60683V6.3335C9.3325 6.70016 9.6325 7.00016 9.99917 7.00016C10.3658 7.00016 10.6658 6.70016 10.6658 6.3335V1.00016C10.6658 0.633496 10.3658 0.333496 9.99917 0.333496H4.66583C4.29917 0.333496 3.99917 0.633496 3.99917 1.00016Z" fill="#2A62FE" />
                              </svg>
                            </a>
                            <a
                              className='download'
                              rel="noopener noreferrer"
                              download
                              target='_blank'
                              href={record.assets.mp4}
                              onClick={() => dispatch(markVideoRecordAsSeen(firebase, dispatch,
                                campaign.uid, selectedReply.uid, recordIndex))}>
                              Download Video
                            </a>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </>
          );
        }}
      />
      {/* <ConfirmModal
        show={itemToDelete != null}
        onDismiss={() => {
          confirmItemDeletion(null);
          dispatch({ type: 'UPDATE_DELETE_STATE', value: {} });
        }}
        confirmButtonProps={{
          className: 'danger',
          icon: <RemoveIcon height={14} width={15} />,
          text: 'Remove',
          onClick: () => deleteVideoCampaign(firebase, dispatch, itemToDelete.uid),
          loading: isInProgress(deleteVideoCampaignState),
        }}>
        <span className='title'>Remove this item?</span>
        {isFailed(deleteVideoCampaignState) && <span className='error'>{deleteVideoCampaignState.error.message}</span>}
      </ConfirmModal> */}
    </div>
  );
};

VideoCampaignViewer.defaultProps = {
};
VideoCampaignViewer.propTypes = {
  className: PropTypes.string,
  uid: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
};

export default VideoCampaignViewer;