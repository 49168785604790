/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';
import readXlsxFile from 'read-excel-file';


export function parseBulkCampaignsFromExcel(file, locations, blacklist, filters, callback) {
  readXlsxFile(file).then((rows) => {
    const data = {};
    filters.forEach(filter => {
      rows.forEach((row, index) => {
        // check columns
        if (!Number.isInteger(row[3]) || _.isEmpty(row[7]) || _.isEmpty(row[13])) {
          console.warn(`[ExcelParser] skip row #${index}, invalid format`);
          return;
        }
        // check age
        if (row[3] > 18 === false) {
          console.warn(`[ExcelParser] skip row #${index}, age < 18`);
          return;
        }
        // fake phone & email
        const email = _.lowerCase(row[5]);
        if (email && blacklist && blacklist.emails &&
          blacklist.emails.find(pattern => email.toLowerCase().includes(pattern))) {
          console.warn(`[ExcelParser] skip row #${index}, email "${row[5]}" blacklisted`);
          return;
        }
        const phone = _.lowerCase(row[46]);
        if (phone && blacklist && blacklist.phones &&
          blacklist.phones.find(pattern => phone.toLowerCase().includes(pattern))) {
          console.warn(`[ExcelParser] skip row #${index}, phone number "${row[16]}" blacklisted`);
          return;
        }
        if (!email && !phone) {
          console.warn(`[ExcelParser] skip row #${index}, no email or phone number`);
          return;
        }
        // filter
        if (filter.departments.includes(row[13]) == false) {
          console.warn(`[ExcelParser] skip row #${index}, filter ${filter.name} does not have department ${row[13]}`);
          return;
        }
        const doctor = row[7].replace(/[,.]/, '').toLowerCase();
        if (_.isEmpty(filter.doctors) === false &&
          filter.doctors.find(name => name.replace(/[,.]/, '').toLowerCase() === doctor) == null) {
          console.warn(`[ExcelParser] skip row #${index}, doctor "${doctor}" is not in filter doctor list`);
          return;
        }
        if (_.isEmpty(filter.excludeDoctors) === false &&
          filter.excludeDoctors.find(name => name.replace(/[,.]/, '').toLowerCase() === doctor)) {
          console.warn(`[ExcelParser] skip row #${index}, doctor "${doctor}" is in filter excluded doctor list`);
          return;
        }
        if (data[filter.uid] == undefined) {
          data[filter.uid] = [];
        }
        // check dups
        if (data[filter.uid].find(r => r[0] === row[0])) {
          console.warn(`[ExcelParser] skip row #${index}, MRN "${row[0]}" already exists in "${row[13]}"`);
          return;
        }
        data[filter.uid].push(row);
      });
    });
    callback(null, _.transform(data, (acc, value, key) => {
      const filter = filters.find(filter => filter.uid === key);
      const location = locations.find(loc => loc.uid === filter.location);
      if (!location) {
        console.warn(`[ExcelParser] skip department #${key}, no location found`);
        return;
      }
      acc.push({
        location: location.uid,
        destinations: _.uniq(
          _.flatten(value.map(row => [row[5], row[16]]))
            .filter(d => d !== null)
        ).join('\n'),
      });
      return acc;
    }, []));
  }).catch(callback);
}