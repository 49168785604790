/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export function loadReviewStats(location) {
  return async function* loadReviewStats(firebase) {
    const locationId = location.uid;
    yield { type: 'UPDATE_LOAD_REVIEW_STATS_STATE', locationId, value: inProgress() };
    try {
      const args = { location: locationId };
      const { data } = await firebase.functions().httpsCallable('getReviewStats')(args);
      if (data.status === 'ok')
        yield { type: 'UPDATE_LOAD_REVIEW_STATS_STATE', locationId, value: success(data) };
      else
        yield { type: 'UPDATE_LOAD_REVIEW_STATS_STATE', locationId, value: failed(data) };
    } catch (error) {
      yield { type: 'UPDATE_LOAD_REVIEW_STATS_STATE', locationId, value: failed(error) };
    }
  };
}

export function loadMonthlyReviewStats(locationUid, periodStarts, periodEnds) {
  return async function* loadReviewStats(firebase) {
    yield { type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: inProgress() };
    try {
      const args = {
        location: locationUid,
        periodStarts,
        periodEnds,
      };
      const { data } = await firebase.functions().httpsCallable('getMonthlyReviewStats')(args);
      if (data.status === 'ok')
        yield { type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: success(data.stats) };
      else
        yield { type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: failed(data) };
    } catch (error) {
      yield { type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: failed(error) };
    }
  };
}