/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import * as yup from 'yup';

import { Button, IconButton } from 'components/buttons';
import Form from 'components/forms/Form';
import FormInputField from 'components/forms/FormInputField';
import { CloseIcon, HideIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import { isInProgress, isSuccessful } from 'utils';

import FormSelectField from 'components/forms/FormDropdownField';
import { diffs } from 'utils/object';
import { save } from './actions';
import { initialState, reducer } from './reducer';

const formSchema = yup.object().shape({
  displayName: yup.string().required('Required'),
  email: yup.string().email('Invalid Email').required('Required'),
});
const passwordSchema = yup.object().shape({
  password: yup.string().min(6, 'At least 6 characters').required('Required'),
});

const AccountEditor = ({ className, account, locations, onClose, onSaved, ...rest }) => {
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [{
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);
  const [showPassword, setShowPassword] = useState(false);

  // state effects
  useEffect(() => dispatch({ type: 'UPDATE_FORM_DATA', value: account }), []);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);
  useEffect(() => {
    if (isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  //  callbacks
  const validateAndSave = useCallback(async () => {
    try {
      await formSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    if (account.uid == null || formData.password != null) {
      try {
        await passwordSchema.validate(formData, { abortEarly: false });
      } catch (error) {
        return error.inner.forEach(e =>
          dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
      }
    }
    const changes = diffs(formData, account);
    save(firebase, dispatch, { uid: account.uid, ...changes });
  }, [formData]);
  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  return (
    <Bloc className={[styles.accountBloc, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{_.isEmpty(account) ? 'Add New Account' : 'Edit Account'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <Form
        className={styles.form}
        error={_.get(formErrors, 'form')} >
        <FormInputField
          name='displayName'
          label="Account Name"
          type="name"
          value={_.get(formData, 'displayName', '')}
          error={_.get(formErrors, 'displayName', '')}
          onChange={onChange}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        <FormInputField
          name='email'
          label="Email"
          type="email"
          value={_.get(formData, 'email', '')}
          error={_.get(formErrors, 'email', '')}
          onChange={onChange}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        <div>
          <FormInputField
            name='password'
            className={styles.formPasswordField}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={_.get(formData, 'password', account.uid ? '**********' : '')}
            error={_.get(formErrors, 'password', '')}
            onChange={onChange}
            onSubmit={validateAndSave}
            disabled={isInProgress(saveState)}
          />
          <IconButton
            style={{ display: _.isEmpty(formData.password) ? 'none' : 'block' }}
            className={styles.passwordHideIcon}
            onClick={() => setShowPassword(!showPassword)}>
            <HideIcon />
          </IconButton>
        </div>
        <FormSelectField
          label="Role"
          placeholder={'Select Account Role'}
          options={[{
            value: 'admin',
            name: 'Admin'
          }, {
            value: 'manager',
            name: 'Manager'
          }, {
            value: 'reviewer',
            name: 'Reviewer'
          }]}
          value={(() => {
            if (formData.customClaims == null)
              return '';
            if (formData.customClaims.admin)
              return 'admin';
            if (formData.customClaims.manager)
              return 'manager';
            return 'reviewer';
          })()}
          error={_.get(formErrors, 'role', '')}
          onChange={(e) => {
            if (e.target.value === 'admin')
              onChange({
                target: {
                  name: 'customClaims',
                  value: {
                    admin: true,
                  },
                }
              });
            else if (e.target.value === 'manager')
              onChange({
                target: {
                  name: 'customClaims',
                  value: {
                    manager: true,
                    locations: [],
                  },
                }
              });
            else
              onChange({
                target: {
                  name: 'customClaims',
                  value: {
                    reviewer: true,
                  },
                }
              });
          }}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        {_.get(formData, 'customClaims.manager') === true && (
          <div className={styles.locationPicker}>
            <span className='label'>Locations</span>
            <div className='box'>
              {locations.map(location => (
                <div key={location.uid}>
                  <input
                    id={`location_${location.uid}`}
                    type='checkbox'
                    checked={formData.customClaims.locations.includes(location.uid)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChange({
                          target: {
                            name: 'customClaims',
                            value: {
                              manager: true,
                              locations: [
                                ...formData.customClaims.locations,
                                location.uid,
                              ],
                            },
                          }
                        });
                      } else {
                        onChange({
                          target: {
                            name: 'customClaims',
                            value: {
                              manager: true,
                              locations: formData.customClaims.locations.filter(l => l !== location.uid),
                            },
                          }
                        });
                      }
                    }}
                  />
                  <label htmlFor={`location_${location.uid}`}>{location.name}</label>
                </div>
              ))}
            </div>
          </div>
        )}
        <Button
          className={styles.saveButton}
          text={_.isEmpty(account) ? 'Add Account' : 'Save Changes'}
          onClick={validateAndSave}
          loading={isInProgress(saveState)} />
      </Form>
    </Bloc>
  );
};

AccountEditor.defaultProps = {
};
AccountEditor.propTypes = {
  className: PropTypes.string,
  account: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default AccountEditor;