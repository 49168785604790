/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function syncNegativeReviews(firebase, dispatch, location) {
  dispatch({ type: 'SYNC_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('negative-reviews').child(location.uid).orderByKey();
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      const items = _.keys(data).map(uid => ({ uid, ...data[uid] }));
      dispatch({ type: 'SYNC_STATE', value: success(items.reverse()) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'SYNC_STATE', value: failed(error) });
  }
}

export async function deleteReview(firebase, dispatch, location, review) {
  dispatch({ type: 'DELETE_REVIEW_STATE', value: inProgress() });
  try {
    await firebase.database().ref('negative-reviews')
      .child(location.uid)
      .child(review.uid)
      .remove();
    dispatch({ type: 'DELETE_REVIEW_STATE', value: success(review) });
  } catch (error) {
    dispatch({ type: 'DELETE_REVIEW_STATE', value: failed(error) });
  }
}