/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ className, children }) => {
  const classes = [styles.error, className];
  return (
    <span className={classes.join(' ')}>{children}</span>
  );
};

Error.defaultProps = {
};
Error.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Error;