/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const HideIcon = ({ className }) => (
  <svg className={className} width="20" height="17" viewBox="0 0 20 17">
    <g opacity="0.62">
      <path data-name="Hide" d="M1.784,16.783a.738.738,0,0,1-.088-.925l.029-.04L16.156,1.257A.247.247,0,0,0,16.2,1.2a.327.327,0,0,0,.05-.059l.917-.925A.743.743,0,0,1,17.692,0a.723.723,0,0,1,.514.214.722.722,0,0,1,.219.522.722.722,0,0,1-.219.521L16.43,3.049a14.777,14.777,0,0,1,3.513,5.159.765.765,0,0,1,0,.581C17.852,13.7,14.135,16.626,10,16.626h-.01a9.091,9.091,0,0,1-5.279-1.752l-1.893,1.91A.728.728,0,0,1,2.3,17,.7.7,0,0,1,1.784,16.783ZM7.8,11.752A3.892,3.892,0,0,0,13.893,8.5a3.989,3.989,0,0,0-.673-2.216L12.156,7.362A2.481,2.481,0,0,1,12.429,8.5,2.444,2.444,0,0,1,10,10.955a2.409,2.409,0,0,1-1.132-.275ZM.059,8.793a.747.747,0,0,1,0-.581C2.147,3.309,5.865.385,9.992.385H10a8.851,8.851,0,0,1,4,.965L10.744,4.639A4.506,4.506,0,0,0,10,4.569,3.92,3.92,0,0,0,6.1,8.508a4.566,4.566,0,0,0,.068.748L2.556,12.9A15.833,15.833,0,0,1,.059,8.793Z" transform="translate(0 0)" fill="#202020" />
    </g>
  </svg>
);

HideIcon.defaultProps = {
};
HideIcon.propTypes = {
  className: PropTypes.string,
};

export default HideIcon;