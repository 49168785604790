/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';
import 'react-datetime/css/react-datetime.css';

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { AiOutlineClose } from 'react-icons/ai';

import { IconButton } from 'components/buttons';

const Component = ({
  name, className, label, error, value, placeholder, onChange,
  disabled, format, resetButtonClassName,
  ...rest
}) => (
  <div className={[styles.component, className].join(' ')}>
    <div className={styles.header}>
      <span className={styles.label}>{label}</span>
      <span className={styles.error}>{error}</span>
    </div>
    <Datetime
      closeOnSelect
      className={styles.control}
      closeOnClickOutside
      inputProps={{
        placeholder,
        readOnly: true,
        disabled,
        value: _.isEmpty(value) ? '' : moment(value).format(format),
      }}
      value={value == null ? '' : moment(value)}
      onChange={(date) => {
        onChange({
          target: {
            name,
            value: date.toISOString(),
          }
        });
      }}
      {...rest}
    />
    {value != null && !disabled &&
      <IconButton
        className={[styles.resetButton, resetButtonClassName].join(' ')}
        onClick={() => onChange({ target: { name, value: null } })} >
        <AiOutlineClose className={styles.resetLinkIcon} size='17px' />
      </IconButton>
    }
  </div>
);

Component.defaultProps = {
  disabled: false,
  format: 'MMMM Do YYYY, h:mm:ss a',
};
Component.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  resetButtonClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  format: PropTypes.string,
};

export default Component;