/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer } from 'react';
import * as yup from 'yup';

import { Button, IconButton } from 'components/buttons';
import Form from 'components/forms/Form';
import { DropdownField, TagsInputField } from 'components/forms';
import FormInputField from 'components/forms/FormInputField';
import { CloseIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import { isInProgress, isSuccessful } from 'utils';

import { save } from './actions';
import { initialState, reducer } from './reducer';

const formSchema = yup.object().shape({
  name: yup.string().required('Required'),
  location: yup.string().required('Required'),
  departments: yup.array()
    .required('At least one department must be specified')
    .min(1, 'At least one department must be specified')
});

const DataFilterBloc = ({ className, data, locations, onClose, onSaved, ...rest }) => {
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [{
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);

  // state effects
  useEffect(() => {
    if (data != null)
      dispatch({ type: 'UPDATE_FORM_DATA', value: data });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);

  useEffect(() => {
    if (isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  //  callbacks
  const validateAndSave = useCallback(async () => {
    try {
      await formSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    save(firebase, dispatch, formData);
  }, [formData]);

  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  return (
    <Bloc className={[styles.datafilterBloc, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{_.isEmpty(data) ? 'New Data Filter' : 'Edit Data Filter'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <Form
        className={styles.form}
        error={_.get(formErrors, 'form')} >
        <FormInputField
          name='name'
          label="Name"
          placeholder="Enter Name"
          type="name"
          value={_.get(formData, 'name', '')}
          error={_.get(formErrors, 'name', '')}
          onChange={onChange}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        <DropdownField
          name='location'
          as="select"
          label='Location'
          placeholder='Select Location'
          value={_.get(formData, 'location', '')}
          error={_.get(formErrors, 'location', '')}
          disabled={isInProgress(saveState)}
          options={locations.map((loc) => ({
            value: loc.uid,
            name: loc.name + ' (' + loc.address + ')',
          }))}
          onChange={onChange}
        />
        <TagsInputField
          name='departments'
          label='Departments'
          value={_.get(formData, 'departments', [])}
          error={_.get(formErrors, 'departments', '')}
          onlyUnique
          inputProps={{ placeholder: 'Department ID' }}
          onChange={(value) => {
            dispatch({
              type: 'UPDATE_FORM_ITEM',
              path: 'departments',
              value,
            });
          }}
        />
        <TagsInputField
          name='doctors'
          label='Include Doctors (Optional)'
          value={_.get(formData, 'doctors', [])}
          error={_.get(formErrors, 'doctors', '')}
          onlyUnique
          inputProps={{ placeholder: 'First Name, Last Name' }}
          onChange={(tags) => {
            dispatch({
              type: 'UPDATE_FORM_ITEM',
              path: 'doctors',
              value: tags.map(t => t.trim())
            });
          }}
        />
        <TagsInputField
          className={styles.blackTagsInput}
          name='excludeDoctors'
          label='Exclude Doctors (Optional)'
          value={_.get(formData, 'excludeDoctors', [])}
          error={_.get(formErrors, 'excludeDoctors', '')}
          onlyUnique
          inputProps={{ placeholder: 'First Name, Last Name' }}
          onChange={(tags) => {
            dispatch({
              type: 'UPDATE_FORM_ITEM',
              path: 'excludeDoctors',
              value: tags.map(t => t.trim())
            });
          }}
        />
        <Button
          className={styles.saveButton}
          text={_.isEmpty(data) ? 'Add Data Filter' : 'Save Changes'}
          onClick={validateAndSave}
          loading={isInProgress(saveState)} />
      </Form>
    </Bloc>
  );
};

DataFilterBloc.defaultProps = {
};
DataFilterBloc.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default DataFilterBloc;