/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';
import React, { createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import firebase from 'firebase';

import firebaseConfig from 'firebase.config';
import AuthRoute from './auth/Login';
import HomeContainer from './home';

import { useReducer } from './reducer';

if (process.env.NODE_ENV === 'development') {
  firebaseConfig.databaseURL = 'https://review-x-dev-db.europe-west1.firebasedatabase.app/';
}
const firebaseApp = firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'development') {
  firebaseApp.functions().useEmulator('localhost', 5001);
}
export const AppContext = createContext();

export default function App() {
  return (
    <AppContext.Provider value={{ firebase: firebaseApp, useReducer }}>
      <ToastContainer
        position='top-center'
        hideProgressBar
        autoClose={2000}
        className={styles.toastContainer}
        toastClassName={styles.toast}
      />
      <Router>
        <Switch>
          <Route path="/">
            <AuthRoute render={(props) => <HomeContainer {...props} />} />
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}