/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import {set} from 'lodash';
import produce from 'immer';

export const reducer = (state, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case 'UPDATE_SAVE_STATE': {
        draftState.saveState = action.value;
        break;
      }
      case 'UPDATE_CONNECT_STATE': {
        draftState.connectState = action.value;
        break;
      }
      case 'UPDATE_DISCONNECT_STATE': {
        draftState.disconnectState = action.value;
        break;
      }
      case 'UPDATE_LOCATIONS_STATE': {
        draftState.locationsState = action.value;
        break;
      }
      case 'UPDATE_GET_LOCATION_STATE': {
        draftState.getLocationState = action.value;
        break;
      }
      case 'UPDATE_FORM_DATA': {
        draftState.formData = action.value;
        break;
      }
      case 'UPDATE_FORM_ITEM': {
        set(draftState.formData, action.path, action.value);
        break;
      }
      case 'UPDATE_FORM_ERROR': {
        set(draftState.formErrors, action.path, action.value);
        break;
      }
      default:
        return draftState;
    }
  });
