/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const LocationsIcon = ({ className, active, width, height, ...rest }) => (
  <svg className={[className].join(' ')} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 16.47" {...rest}>
    <g>
      <linearGradient id="linear-gradient" x1="0.738" y1="0.816" x2="-0.07" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#084edd" />
        <stop offset="1" stopColor="#489ff9" />
      </linearGradient>
      <path fill={active ? 'url(#linear-gradient)' : '#5E5F60'} d="M7,16.47a1.118,1.118,0,0,1-.6-.2,17.716,17.716,0,0,1-4.563-4.234A8.552,8.552,0,0,1,0,6.85,6.727,6.727,0,0,1,2.061,2,7.025,7.025,0,0,1,6.994,0,6.937,6.937,0,0,1,14,6.85a8.556,8.556,0,0,1-1.837,5.183A18.052,18.052,0,0,1,7.6,16.266,1.087,1.087,0,0,1,7,16.47ZM6.994,4.758A2.308,2.308,0,0,0,4.689,7.063a2.233,2.233,0,0,0,.676,1.609A2.325,2.325,0,0,0,9.311,7.063,2.326,2.326,0,0,0,6.994,4.758Z" />
    </g>
  </svg>
);

LocationsIcon.defaultProps = {
  active: false,
  width: 15,
  height: 16,
};
LocationsIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LocationsIcon;