/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { Button } from 'components/buttons';

const ConfirmModal = ({ className, show, onDismiss, children, dismissButtonProps, confirmButtonProps, ...rest }) => {
  const classes = [styles.dialog, className];
  return (
    <Modal contentClassName={styles.modal} show={show} onHide={onDismiss}>
      <div className={classes.join(' ')} {...rest}>
        <div className={styles.content}>
          {children}
        </div>
        <div className={styles.buttons}>
          <Button
            text="Dismiss"
            onClick={onDismiss}
            {...dismissButtonProps}
          />
          <Button
            text="Okay"
            {...confirmButtonProps}
          />
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  show: false,
};
ConfirmModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  dismissButtonProps: PropTypes.object,
  confirmButtonProps: PropTypes.object,
};

export default ConfirmModal;