/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function syncLocations(firebase, dispatch, claims) {
  dispatch({ type: 'UPDATE_SYNC_LOCATIONS_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('locations');
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      const items = _.keys(data)
        .filter(uid => claims.locations ? claims.locations.includes(uid) : true)
        .map(uid => ({ uid, ...data[uid] }));
      dispatch({ type: 'UPDATE_SYNC_LOCATIONS_STATE', value: success(items) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_LOCATIONS_STATE', value: failed(error) });
  }
}

export async function syncClients(firebase, dispatch) {
  dispatch({ type: 'UPDATE_SYNC_CLIENTS_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('clients');
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      const items = _.keys(data).map(uid => ({ uid, ...data[uid] }));
      dispatch({ type: 'UPDATE_SYNC_CLIENTS_STATE', value: success(items) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_CLIENTS_STATE', value: failed(error) });
  }
}

export async function syncDataFilters(firebase, dispatch) {
  dispatch({ type: 'UPDATE_SYNC_DATAFILTERS_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('data-filters');
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      const items = _.keys(data).map(uid => ({ uid, ...data[uid] }));
      dispatch({ type: 'UPDATE_SYNC_DATAFILTERS_STATE', value: success(items) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_DATAFILTERS_STATE', value: failed(error) });
  }
}

export async function syncBlacklist(firebase, dispatch) {
  dispatch({ type: 'UPDATE_SYNC_BLACKLIST_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('blacklist');
    ref.on('value', (snapshot) => {
      const data = snapshot.val() || {};
      dispatch({ type: 'UPDATE_SYNC_BLACKLIST_STATE', value: success(data) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_BLACKLIST_STATE', value: failed(error) });
  }
}

export async function loadReviews(firebase, dispatch, location) {
  dispatch({ type: 'UPDATE_LOAD_REVIEWS_STATE', value: inProgress() });
  try {
    const args = { location: location.uid };
    const res = await firebase.functions().httpsCallable('getReviews')(args);
    dispatch({ type: 'UPDATE_LOAD_REVIEWS_STATE', value: success(res.data) });
  } catch (error) {
    dispatch({ type: 'UPDATE_LOAD_REVIEWS_STATE', value: failed(error) });
  }
}

export async function loadMoreReviews(firebase, dispatch, location, nextPageToken) {
  dispatch({ type: 'UPDATE_LOAD_MORE_REVIEWS_STATE', value: inProgress() });
  try {
    const args = { location: location.uid, nextPageToken };
    const res = await firebase.functions().httpsCallable('getReviews')(args);
    dispatch({ type: 'UPDATE_LOAD_MORE_REVIEWS_STATE', value: success(res.data) });
  } catch (error) {
    dispatch({ type: 'UPDATE_LOAD_MORE_REVIEWS_STATE', value: failed(error) });
  }
}

export async function syncPreRepliedReviews(firebase, dispatch) {
  dispatch({ type: 'UPDATE_SYNC_PRE_REPLIED_REVIEWS_STATE', value: inProgress() });
  try {
    const ref = firebase.database().ref('prereplied-reviews');
    ref.on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const values = _.entries(data).map(([uid, value]) => ({ uid, ...value }));
      dispatch({ type: 'UPDATE_SYNC_PRE_REPLIED_REVIEWS_STATE', value: success(values) });
    });
    return () => ref.off('value');
  } catch (error) {
    dispatch({ type: 'UPDATE_SYNC_PRE_REPLIED_REVIEWS_STATE', value: failed(error) });
  }
}