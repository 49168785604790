/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function deleteDataFilter(firebase, dispatch, uid) {
  dispatch({ type: 'UPDATE_DELETE_DATAFILTER_STATE', value: inProgress() });
  try {
    const res = await firebase.functions().httpsCallable('deleteDataFilter')(uid);
    if (res.data.status !== 'ok')
      dispatch({ type: 'UPDATE_DELETE_DATAFILTER_STATE', value: failed(res.data) });
    else
      dispatch({ type: 'UPDATE_DELETE_DATAFILTER_STATE', value: success(uid) });
  } catch (error) {
    dispatch({ type: 'UPDATE_DELETE_DATAFILTER_STATE', value: failed(error) });
  }
}