/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/buttons';
import { EditIcon, RemoveIcon } from 'components/icons';

const ListItem = ({ className, children, onEdit, onDelete, onOpen, ...rest }) => {
  const classes = [styles.listItem, className];
  return (
    <div className={classes.join(' ')} {...rest}>
      <div className={styles.content}>
        {children}
      </div>
      <div className={onEdit || onOpen || onDelete ? styles.buttons : null}>
        {onOpen && (
          <Button
            className={styles.edit}
            text="View"
            onClick={onOpen}
          />
        )}
        {onEdit && (
          <Button
            className={styles.edit}
            icon={<EditIcon height={12} width={12} />}
            text="Edit"
            onClick={onEdit}
          />
        )}
        {onDelete && (
          <Button
            className={styles.remove}
            icon={<RemoveIcon height={14} width={15} />}
            text="Remove"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};

ListItem.defaultProps = {
};
ListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onOpen: PropTypes.func,
};

export default ListItem;