/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';

import { failed, inProgress, success } from 'utils';


export function bulkSend(data) {
  return async function* (firebase, dispatch) {
    try {
      const destinations = data.destinations.split('\n').map(d => d.trim()).filter(d => d.length)
        .map(value => ({ value, status: 'pending' }));
      yield { type: 'UPDATE_BULK_SEND_STATE', value: inProgress(destinations) };

      const res = await firebase.functions().httpsCallable('createHistoricalLog')({
        location: data.location,
        note: data.historicalLogNote,
        initializeAt: data.initializeAt == null
          ? undefined
          : moment(data.initializeAt).utc().toISOString(),
        bulkCampaign: {
          destinations
        },
      });
      if (res.data.status === 'ok') {
        if (data.initializeAt) {
          dispatch({ type: 'UPDATE_BULK_SEND_STATE', value: success(destinations) });
        } else {
          const emitter = new BehaviorSubject();
          emitter.pipe(
            bufferTime(1000),
            filter((buff) => !_.isEmpty(buff)),
            map((buff) => _.last(buff)),
          ).subscribe(dispatch);

          const ref = firebase.database().ref(`historical-logs/${res.data.logUid}`);
          ref.on('value', (snapshot) => {
            const destinations = snapshot.val().bulkCampaign.destinations;
            let done = true;
            for (const item of destinations) {
              if (['pending', 'sending'].includes(item.status))
                done = false;
            }
            if (done) {
              ref.off('value');
              emitter.next({ type: 'UPDATE_BULK_SEND_STATE', value: success(destinations) });
            } else {
              emitter.next({ type: 'UPDATE_BULK_SEND_STATE', value: inProgress(destinations) });
            }
          });
        }
      } else {
        yield { type: 'UPDATE_BULK_SEND_STATE', value: failed(res.data) };
      }
    } catch (error) {
      yield { type: 'UPDATE_BULK_SEND_STATE', value: failed(error) };
    }
  };
}