/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { ImFilter } from 'react-icons/im';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button } from 'components/buttons';
import { RemoveIcon } from 'components/icons';
import { ConfirmModal } from 'components/modals';
import { AppContext } from 'containers';
import {
  AsyncStateSelector, isFailed, isInProgress, isSuccessful,
  parseBulkCampaignsFromExcel,
} from 'utils';

import Header from '../Header';
import ListItem from '../ListItem';
import SearchResultMsg from '../SearchResultMsg';

import { deleteDataFilter } from './actions';
import { initialState, reducer } from './reducer';

const DataFilterList = ({
  className, syncDataFiltersState, locations, blacklist,
  onEdit, onAdd, onBulkCampaignData,
}) => {
  const classes = [styles.container, className];
  // contexts
  const { __, firebase } = React.useContext(AppContext);
  // state
  const [{ deleteState }, dispatch] = useReducer(reducer, initialState);
  const [itemToDelete, confirmItemDeletion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // effects
  useEffect(() => {
    if (isSuccessful(deleteState) && itemToDelete != null)
      confirmItemDeletion(null);
  }, [deleteState]);

  const searchMode = _.isEmpty(searchQuery) === false;
  return (
    <div className={classes.join(' ')}>
      <Header title='Data Filters' onSearch={setSearchQuery}>
        <Button
          className={styles.addDataFilterButton}
          text="+ Add Data Filter"
          onClick={onAdd} />
      </Header>
      <AsyncStateSelector
        state={syncDataFiltersState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load datafilters</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={(filters) => {
          if (searchMode) {
            const query = searchQuery.toUpperCase();
            filters = filters.filter(item =>
              item.code.toUpperCase().includes(query));
          }
          return (
            <>
              {searchMode &&
                <SearchResultMsg
                  text={`We found ${filters.length} results for data filter "${searchQuery}"`} />
              }
              <div className={styles.list}>
                {!_.isEmpty(filters) &&
                  <Row className={styles.row}>
                    {filters.map(item =>
                      <Card
                        key={`filter_${item.uid}`}
                        data={item}
                        locations={locations}
                        blacklist={blacklist}
                        onEdit={onEdit}
                        onDelete={confirmItemDeletion}
                        onBulkCampaignData={onBulkCampaignData} />)}
                  </Row>
                }
                <ConfirmModal
                  show={itemToDelete != null}
                  onDismiss={() => confirmItemDeletion(null)}
                  confirmButtonProps={{
                    className: 'danger',
                    icon: <RemoveIcon height={14} width={15} />,
                    text: 'Remove',
                    onClick: () => deleteDataFilter(firebase, dispatch, itemToDelete.uid),
                    loading: isInProgress(deleteState),
                  }}
                >
                  <span className='title'>Remove this item?</span>
                  {isFailed(deleteState) && <span className='error'>{deleteState.error.message}</span>}
                </ConfirmModal>
              </div>
            </>
          );
        }}
      />
    </div >
  );
};

DataFilterList.defaultProps = {
};
DataFilterList.propTypes = {
  className: PropTypes.string,
  syncDataFiltersState: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequire,
  blacklist: PropTypes.object.isRequire,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onBulkCampaignData: PropTypes.func.isRequired,
};

export default DataFilterList;

function Card({ data: filter, locations, blacklist, onEdit, onDelete, onBulkCampaignData }) {
  const [isParsingExcelFile, setParsingExcelFile] = useState(false);
  const location = useMemo(() => locations.find(l => l.uid === filter.location),
    [locations, filter.location]);

  // dropzone
  const onDrop = useCallback(files => {
    setParsingExcelFile(true);
    parseBulkCampaignsFromExcel(files[0], locations, blacklist, [filter],
      (error, data) => {
        setParsingExcelFile(false);
        if (error) {
          console.error('failed to parse excel file', error);
          toast.error('Failed to parse Excel file!');
          return;
        }

        if (_.isEmpty(data)) {
          toast.warn('No suitable data found');
        } else {
          onBulkCampaignData(data);
        }
      });
  });
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, noClick: true, noKeyboard: true });

  return <Col key={`datafilter_${filter.uid}`} lg="3" md="4" sm="5">
    <ListItem
      onEdit={isDragActive || isParsingExcelFile ? null : () => onEdit(filter)}
      onDelete={isDragActive || isParsingExcelFile ? null : () => onDelete(filter)}
      {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={styles.dropzone}>
          <FontAwesomeIcon icon={faFileExcel} color='#9d9b9b' size='2x' />
          <p>Drop Excel File Here</p>
        </div>
      ) : isParsingExcelFile ? (
        <div className={styles.dropzone}>
          <BackLoadingIndicator height='30px' />
          <p>Parsing Excel File...</p>
        </div>
      ) : (
        <>
          <div className='d-inline-flex'>
            <ImFilter style={{ marginRight: 5, marginTop: -1 }} size='17px' />
            <p className={styles.title}>{filter.name}</p>
          </div>
          <p className={styles.subtitle}>
            {_.get(location, 'name')}
          </p>
        </>
      )}
    </ListItem>
  </Col>;
}

Card.defaultProps = {
};
Card.propTypes = {
  data: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  blacklist: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBulkCampaignData: PropTypes.func.isRequired,
};