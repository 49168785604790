/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

export const firebaseConfig = {
  apiKey: 'AIzaSyDqQECTnQ3JqUsHNZOovQof6MBn4pNN6Vg',
  authDomain: 'reviews-web-app.firebaseapp.com',
  databaseURL: 'https://reviews-web-app-default-rtdb.firebaseio.com',
  projectId: 'reviews-web-app',
  storageBucket: 'reviews-web-app.appspot.com',
  messagingSenderId: '64480040393',
  appId: '1:64480040393:web:8a69eba7c3a6d4e7338418',
  measurementId: 'G-E9G35G5C5K'
};
export default firebaseConfig;