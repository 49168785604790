/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from 'lodash';

import { failed, inProgress, success } from 'utils';

export async function login(firebase, dispatch, email, password) {
  dispatch({ type: 'UPDATE_LOGIN_STATE', value: inProgress() });
  try {
    const auth = await firebase.auth().signInWithEmailAndPassword(email, password);
    const idTokenResult = await auth.user.getIdTokenResult();
    const claims = idTokenResult.claims;
    if (!claims.admin && !claims.manager && !claims.reviewer)
      throw new Error('Forbidden. This user is not allowed to login');
    dispatch({ type: 'UPDATE_LOGIN_STATE', value: success(auth.user) });
  } catch (error) {
    dispatch({ type: 'UPDATE_LOGIN_STATE', value: failed(error) });
  }
}