/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import Button from 'components/buttons/Button';

const SearchInput = ({ className, value, onChange, onSearch, onClear, disabled, searchFor, ...rest }) => (
  <div className={[styles.searchBar, className].join(' ')} {...rest}>
    <div className={styles.searchIcon}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 19.181 19.605">
        <g transform="translate(0.75 0.75)">
          <circle cx="8.321" cy="8.321" r="8.321" transform="translate(0 0)" fill="none" stroke="#084edd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
          <path d="M0,0,3.262,3.254" transform="translate(14.108 14.54)" fill="none" stroke="#084edd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
        </g>
      </svg>
    </div>
    <Form.Control
      name='search'
      className={styles.control}
      type='text'
      disabled={disabled}
      placeholder={`Search for ${searchFor}`}
      value={value}
      onChange={onChange}
      onKeyPress={event => {
        if (onSearch && event.key === 'Enter') {
          onSearch();
        }
      }}
    />
    <div className={styles.closeIcon} onClick={onClear}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15.536" height="15.536" viewBox="0 0 15.536 15.536">
        <g transform="translate(-1124.732 -270.732)" opacity="0.6">
          <line x2="12" y2="12" transform="translate(1126.5 272.5)" fill="none" stroke="#202020" strokeLinecap="round" strokeWidth="2.5" />
          <line x1="12" y2="12" transform="translate(1126.5 272.5)" fill="none" stroke="#202020" strokeLinecap="round" strokeWidth="2.5" />
        </g>
      </svg>
    </div>
    {onSearch && (
      <Button className={styles.searchButton} text='Search' onClick={onSearch} />
    )}
  </div>
);

SearchInput.defaultProps = {
};
SearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  searchFor: PropTypes.string,
};

export default SearchInput;