/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const RemoveIcon = ({ className, width, height, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox='0 0 15.5 16.7' {...rest}>
    <g transform="translate(0 -0.001)">
      <path d="M11.716,0s-.448,5.56-.708,7.9A1.847,1.847,0,0,1,9.061,9.7c-2.154.039-4.31.041-6.463,0A1.828,1.828,0,0,1,.708,7.908C.446,5.545,0,0,0,0" transform="translate(1.893 6.296)" fill="none" stroke="#df2c2c" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M14,.5H0" transform="translate(0.75 3.131)" fill="none" stroke="#df2c2c" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M8.6,2.88A1.36,1.36,0,0,1,7.272,1.787l-.2-1A1.056,1.056,0,0,0,6.05,0H2.555A1.056,1.056,0,0,0,1.534.783l-.2,1A1.36,1.36,0,0,1,0,2.88" transform="translate(3.448 0.751)" fill="none" stroke="#df2c2c" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
    </g>
  </svg>
);

RemoveIcon.defaultProps = {
  width: 15.5,
  height: 16.7,
};
RemoveIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RemoveIcon;