/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';

import { DateTimePicker, SelectField } from 'components/forms';
import { ReviewStarIcon } from 'components/icons';
import { AppContext } from 'containers';
import { isFailed, isInProgress, isSuccessful, isUseless } from 'utils';
import Header from '../../components/Header';
import Chart from './chart';

import { loadMonthlyReviewStats, loadReviewStats } from './actions';
import { reducer } from './reducer';
import styles from './styles.module.css';


const LocationReviewStatsList = ({ locations, onClick }) => {
  if (_.isEmpty(locations))
    return null;

  // contexts
  const { useReducer } = React.useContext(AppContext);

  // state
  const [{
    loadReviewStatsState: stateMap,
    loadMonthlyReviewStatsState,
  }, dispatch] = useReducer(reducer, {
    loadReviewStatsState: {},
    loadMonthlyReviewStats: {},
  }, {}, 'location-review-stats');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [periodStarts, setPeriodStarts] = useState(null);
  const [periodEnds, setPeriodEnds] = useState(null);

  // effects
  useEffect(() => {
    for (const location of locations) {
      const state = _.get(stateMap, location.uid);
      if (isUseless(state)) {
        dispatch(loadReviewStats(location));
      }
    }
  }, [locations]);

  useEffect(() => {
    if (selectedLocation && periodStarts && periodEnds) {
      dispatch(loadMonthlyReviewStats(selectedLocation.uid, periodStarts, periodEnds));
    } else {
      dispatch({ type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: {} });
    }
  }, [selectedLocation, periodStarts, periodEnds]);

  useEffect(() => {
    if (isFailed(loadMonthlyReviewStatsState)) {
      toast.error(loadMonthlyReviewStatsState.error.message || 'Failed to load monthly stats', { autoClose: 3200 });
      dispatch({ type: 'UPDATE_LOAD_MONTHLY_REVIEW_STATS_STATE', value: {} });
    }
  }, [loadMonthlyReviewStatsState]);

  return (
    <div className={styles.container}>
      <Header className="header" title='Review Stats'>
        <SelectField
          className={styles.locationsDropdown}
          placeholder='Select location'
          value={_.get(selectedLocation, 'uid', '')}
          onChange={({ value }) => setSelectedLocation(locations.find(l => l.uid === value))}
          options={locations.map(location => ({ value: location.uid, label: location.name }))}
          disabled={isInProgress(loadMonthlyReviewStatsState)}
        />
        <DateTimePicker
          format='MMM Do YYYY'
          timeFormat={false}
          className={styles.dateTimePicker}
          resetButtonClassName={'resetButton'}
          placeholder="Period Starts"
          value={periodStarts}
          onChange={(e) => setPeriodStarts(e.target.value)}
          disabled={isInProgress(loadMonthlyReviewStatsState)}
        />
        <DateTimePicker
          format='MMM Do YYYY'
          timeFormat={false}
          className={styles.dateTimePicker}
          resetButtonClassName={'resetButton'}
          placeholder="Period Ends"
          value={periodEnds}
          onChange={(e) => setPeriodEnds(e.target.value)}
          disabled={isInProgress(loadMonthlyReviewStatsState)}
        />
      </Header>
      {isSuccessful(loadMonthlyReviewStatsState) && (
        <div className={styles.chart}>
          <Chart data={loadMonthlyReviewStatsState.value} />
        </div>
      )}
      <div className={styles.list}>
        <Row>
          {locations.map((location) => {
            const state = _.get(stateMap, location.uid);
            return (
              <Col key={`location_${location.uid}`} lg="2" md="4" sm="5"
                className={onClick ? 'active' : ''}
                onClick={() => onClick && onClick(location)}>
                <p className='title'> {location.name}</p>
                {state == null || isInProgress(state)
                  ? (
                    <ContentLoader viewBox="0 0 100 32">
                      <rect x="30" y="4" rx="4" ry="4" width="70" height="7" />
                      <rect x="50" y="14" rx="3" ry="3" width="50" height="5" />
                      <rect x="60" y="23" rx="3" ry="3" width="40" height="8" />
                    </ContentLoader>
                  )
                  : isFailed(state)
                    ? (
                      <div className={styles.error}>
                        <p className='title'>Failed to retrieve review stats</p>
                        <p className='message'>{_.get(state.error, 'message', 'Server Error')}</p>
                      </div>
                    )
                    : (
                      <div className={styles.content}>
                        <p className='averageRating'>
                          <span>{_.round(state.value.averageRating, 1)}</span>
                          {_.range(0, 5).map(i => <ReviewStarIcon
                            key={`star_${i}`}
                            fill={Math.max(0, Math.min(1, state.value.averageRating - i))}
                          />)}
                        </p>
                        <p className='totalReviews'>{state.value.totalReviewCount} total reviews</p>
                        <p className={'unrepliedReviews' + (state.value.unrepliedReviews > 0 ? ' danger' : '')}>
                          {state.value.unrepliedReviews} unreplied
                        </p>
                      </div>
                    )
                }
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

LocationReviewStatsList.defaultProps = {
};
LocationReviewStatsList.propTypes = {
  locations: PropTypes.array,
  onClick: PropTypes.func,
};

export default LocationReviewStatsList;