/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from './styles.module.css';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FormSelectField = ({ className, label, error, value, options, ...rest }) => {
  return (
    <div className={[styles.container, className].join(' ')}>
      <div className={styles.header}>
        <span className={styles.label}>{label}</span>
        <span className={styles.error}>{error}</span>
      </div>
      <Select
        className={styles.control}
        styles={{
          option: (_styles, { isFocused, isSelected }) => ({
            ..._styles,
            backgroundColor: isFocused ? '#F4F5F8' : 'transparent',
            color: isSelected ? '#1C9AFB' : '#777777',
          })
        }}
        value={!value ? null : _.isArray(value)
          ? options.filter(o => value.includes(o.value))
          : options.find(o => o.value === value)}
        options={options}
        isClearable={false}
        {...rest}
      />
    </div>
  );
};


FormSelectField.defaultProps = {
  disabled: false,
};
FormSelectField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

export default FormSelectField;