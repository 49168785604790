/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const NegativeReviewIcon = ({ className, active, width, height, ...rest }) => (
  <svg className={[className].join(' ')} width={width} height={height} fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <linearGradient id="linear-gradient" x1="0.738" y1="0.816" x2="-0.07" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#084edd" />
        <stop offset="1" stopColor="#489ff9" />
      </linearGradient>
      <path stroke={active ? '#5984dc' : '#5E5F60'}  fill={active ? 'url(#linear-gradient)' : 'transparent'} d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
    </g>
  </svg>
);

NegativeReviewIcon.defaultProps = {
  active: false,
  width: 17,
  height: 17,
};
NegativeReviewIcon.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NegativeReviewIcon;