/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React, { useReducer, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { AppContext } from 'containers';
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful } from 'utils';
import { RemoveIcon, ReviewStarIcon } from 'components/icons';
import { BackLoadingIndicator, BackPrint } from 'components/back';

import SearchResultMsg from '../SearchResultMsg';

import { syncNegativeReviews, deleteReview } from './actions';
import { initialState, reducer } from './reducer';
import { ConfirmModal } from 'components/modals';

const NegativeReviewsBlockedList = ({ location, searchMode, searchQuery, ratings }) => {
  // contexts
  const { firebase } = React.useContext(AppContext);

  // state
  const [{ syncState, deleteReviewState }, dispatch] = useReducer(reducer, initialState);
  const [reviewToDelete, confirmItemDeletion] = useState(null);

  // effects
  useEffect(() => {
    let cancelSyncNegativeReviews;
    syncNegativeReviews(firebase, dispatch, location).then(cancel => {
      cancelSyncNegativeReviews = cancel;
    });
    return () => {
      if (cancelSyncNegativeReviews) cancelSyncNegativeReviews();
    };
  }, [location]);

  useEffect(() => {
    if (isSuccessful(deleteReviewState) && reviewToDelete != null)
      confirmItemDeletion(null);
  }, [deleteReviewState]);

  const copyToClipboard = useCallback((text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      if (successful) toast.info('Copied to Clipboard', { autoClose: 1200 });
    } catch (err) {
      console.error('failed to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  });

  return (
    <div className={styles.container}>
      <AsyncStateSelector
        state={syncState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to load negative reviews</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={(reviews) => {
          if (!_.isEmpty(ratings))
            reviews = reviews.filter(r => ratings.includes(
              ['STAR_RATING_UNSPECIFIED', 'ONE', 'TWO', 'THREE'][r.rating]));
          if (searchMode) {
            const query = searchQuery.toUpperCase();
            reviews = reviews.filter(item =>
              _.values(item).join(' ').toUpperCase().includes(query));
          }
          return (
            <>
              {searchMode
                ? <SearchResultMsg
                  text={`We found ${reviews.length} results for "${searchQuery}"`} />
                : reviews.length === 0
                  ? <SearchResultMsg text="No negative reviews found" />
                  : <p>There is <b>{reviews.length}</b> reviews</p>
              }
              <div className={styles.list}>
                {reviews.map(review => {
                  return (
                    <div key={`review_${review.uid}`}>
                      <div className={styles.header}>
                        <div>
                          <h1>{review.name}</h1>
                          <h3 onClick={() => copyToClipboard(review.email)}>{review.email}
                            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.5 0.833496H3.33333C2.41667 0.833496 1.66667 1.5835 1.66667 2.50016V13.3335C1.66667 13.7918 2.04167 14.1668 2.5 14.1668C2.95833 14.1668 3.33333 13.7918 3.33333 13.3335V3.3335C3.33333 2.87516 3.70833 2.50016 4.16667 2.50016H12.5C12.9583 2.50016 13.3333 2.12516 13.3333 1.66683C13.3333 1.2085 12.9583 0.833496 12.5 0.833496ZM15.8333 4.16683H6.66667C5.75 4.16683 5 4.91683 5 5.8335V17.5002C5 18.4168 5.75 19.1668 6.66667 19.1668H15.8333C16.75 19.1668 17.5 18.4168 17.5 17.5002V5.8335C17.5 4.91683 16.75 4.16683 15.8333 4.16683ZM15 17.5002H7.5C7.04167 17.5002 6.66667 17.1252 6.66667 16.6668V6.66683C6.66667 6.2085 7.04167 5.8335 7.5 5.8335H15C15.4583 5.8335 15.8333 6.2085 15.8333 6.66683V16.6668C15.8333 17.1252 15.4583 17.5002 15 17.5002Z" fill="#AAAAAA" />
                            </svg>
                          </h3>
                        </div>
                        <div>
                          <p>{moment(review.createdAt).format('MMM DD, YYYY')}</p>
                          <p>{moment(review.createdAt).format(' h:mm A')}</p>
                        </div>
                      </div>
                      <Devider />
                      {_.range(0, 5).map(i =>
                        <ReviewStarIcon
                          key={`star_${i}`}
                          fill={Math.max(0, Math.min(1, review.rating - i))}
                          height={34}
                          width={34}
                        />)}
                      {!_.isEmpty(review.improvetags) &&
                        <div className={styles.improvetags}>
                          <h3>Needs to be improved:</h3>
                          <ul>
                            {review.improvetags.map((tag, idx) => <li key={`tag_${idx}`}>{tag}</li>)}
                          </ul>
                        </div>
                      }
                      <p className={styles.comment}>{review.comment}</p>
                      <div className={styles.delete} onClick={() => confirmItemDeletion(review)} >
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
                          <path d="M24 24H26C26.55 24 27 24.45 27 25C27 25.55 26.55 26 26 26H24C23.45 26 23 25.55 23 25C23 24.45 23.45 24 24 24ZM24 16H29C29.55 16 30 16.45 30 17C30 17.55 29.55 18 29 18H24C23.45 18 23 17.55 23 17C23 16.45 23.45 16 24 16ZM24 20H28C28.55 20 29 20.45 29 21C29 21.55 28.55 22 28 22H24C23.45 22 23 21.55 23 21C23 20.45 23.45 20 24 20ZM11 26C11 27.1 11.9 28 13 28H19C20.1 28 21 27.1 21 26V16H11V26ZM21 13H19L18.29 12.29C18.11 12.11 17.85 12 17.59 12H14.41C14.15 12 13.89 12.11 13.71 12.29L13 13H11C10.45 13 10 13.45 10 14C10 14.55 10.45 15 11 15H21C21.55 15 22 14.55 22 14C22 13.45 21.55 13 21 13Z" fill="#FF0000" />
                          <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#E7E7E7" />
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }} />
      <ConfirmModal
        show={reviewToDelete != null}
        onDismiss={() => {
          confirmItemDeletion(null);
          dispatch({ type: 'DELETE_REVIEW_STATE', value: {} });
        }}
        confirmButtonProps={{
          className: 'danger',
          icon: <RemoveIcon height={14} width={15} />,
          text: 'Remove',
          onClick: () => deleteReview(firebase, dispatch, location, reviewToDelete),
          loading: isInProgress(deleteReviewState),
        }}>
        <span className='title'>Remove this review?</span>
        {isFailed(deleteReviewState) && <span className='error'>{deleteReviewState.error.message}</span>}
      </ConfirmModal>
    </div>
  );
};

const Devider = () => {
  return <svg className={styles.devider} viewBox="0 0 1600 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H1600" stroke="#E7E7E7" strokeDasharray="10 8" />
  </svg>;
};

NegativeReviewsBlockedList.defaultProps = {
  searchMode: false,
};
NegativeReviewsBlockedList.propTypes = {
  location: PropTypes.object.isRequired,
  searchQuery: PropTypes.string,
  searchMode: PropTypes.bool,
  ratings: PropTypes.array,
};

export default NegativeReviewsBlockedList;