/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer } from 'react';
import TagsInput from 'react-tagsinput';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { Button, IconButton } from 'components/buttons';
import Form from 'components/forms/Form';
import { CloseIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import { AsyncStateSelector, isInProgress, isSuccessful, success } from 'utils';

import { load, save } from './actions';
import { initialState, reducer } from './reducer';


const BlacklistEditor = ({ className, onClose, onSaved, syncState, ...rest }) => {
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [{
    loadState,
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);

  // state effects
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);

  useEffect(() => {
    if (isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  useEffect(() => {
    if (isSuccessful(syncState)) {
      dispatch({ type: 'UPDATE_LOAD_STATE', value: success(syncState.value) });
    } else {
      load(firebase, dispatch);
    }
  }, [syncState]);

  useEffect(() => {
    if (isSuccessful(loadState))
      dispatch({ type: 'UPDATE_FORM_DATA', value: loadState.value });
  }, [loadState.value]);

  //  callbacks
  const validateAndSave = useCallback(async () => {
    save(firebase, dispatch, formData);
  }, [formData]);

  return (
    <Bloc className={[styles.container, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{'Blacklist'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <AsyncStateSelector
        state={loadState}
        onProgress={() => <BackLoadingIndicator />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to data</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
          </BackPrint>
        )}
        onSuccess={() => (
          <Form
            className={styles.form}
            error={_.get(formErrors, 'form')} >
            <div className={styles.taglist}>
              <span className='label'>Emails</span>
              <TagsInput
                name='emails'
                value={_.get(formData, 'emails', [])}
                onlyUnique
                inputProps={{ placeholder: 'Enter Email' }}
                onChange={(tags) => {
                  dispatch({
                    type: 'UPDATE_FORM_ITEM',
                    path: 'emails',
                    value: tags.map(t => t.trim())
                  });
                }}
              />
            </div>
            <div className={styles.taglist}>
              <span className='label'>Phone Numners</span>
              <TagsInput
                name='phones'
                value={_.get(formData, 'phones', [])}
                onlyUnique
                inputProps={{ placeholder: 'Enter Number' }}
                onChange={(tags) => {
                  dispatch({
                    type: 'UPDATE_FORM_ITEM',
                    path: 'phones',
                    value: tags.map(t => t.trim())
                  });
                }}
              />
            </div>
            <Button
              className={styles.saveButton}
              text={'Save Changes'}
              onClick={validateAndSave}
              loading={isInProgress(saveState)} />
          </Form>
        )}
      />
    </Bloc>
  );
};

BlacklistEditor.defaultProps = {
};
BlacklistEditor.propTypes = {
  className: PropTypes.string,
  syncState: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default BlacklistEditor;