/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from './styles.module.css';

import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';

import { AppContext } from 'containers';
import Form from 'components/forms/Form';
import FormInputField from 'components/forms/FormInputField';
import Bloc from 'components/panels/Bloc';
import { Button, IconButton } from 'components/buttons';
import { CloseIcon, InfoIcon, HideIcon } from 'components/icons';
import { isInProgress, isSuccessful } from 'utils';

import { save } from './actions';
import { initialState, reducer } from './reducer';

const formSchema = yup.object().shape({
  name: yup.string().required('Required'),
  email: yup.string().email('Invalid Email').required('Required'),
});
const passwordSchema = yup.object().shape({
  password: yup.string().min(6, 'At least 6 characters').required('Required'),
});

const ClientBloc = ({ className, client, onClose, onSaved, ...rest }) => {
  const { __, firebase } = React.useContext(AppContext);

  // state
  const [{
    saveState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, initialState);
  const [showPassword, setShowPassword] = useState(false);

  // state effects
  useEffect(() => dispatch({ type: 'UPDATE_FORM_DATA', value: client }), []);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);
  useEffect(() => {
    if (isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  //  callbacks
  const validateAndSave = useCallback(async () => {
    try {
      await formSchema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    if (client.uid == null || formData.password != null) {
      try {
        await passwordSchema.validate(formData, { abortEarly: false });
      } catch (error) {
        return error.inner.forEach(e =>
          dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
      }
    }
    save(firebase, dispatch, formData);
  }, [formData]);
  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({ type: 'UPDATE_FORM_ITEM', path: name, value });
  }, [formErrors]);

  return (
    <Bloc className={[styles.clientBloc, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{_.isEmpty(client) ? 'Add New Client' : 'Edit Client'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <Form
        className={styles.form}
        error={_.get(formErrors, 'form')} >
        <div className={styles.info}>
          <InfoIcon />
          <span>This information will be used to login to the iPad/Tablet for patient review collection.</span>
        </div>
        <FormInputField
          name='name'
          label="Client Name"
          placeholder="Enter client name"
          type="name"
          value={_.get(formData, 'name', '')}
          error={_.get(formErrors, 'name', '')}
          onChange={onChange}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        <FormInputField
          name='email'
          label="Email"
          placeholder="Enter iPad login email"
          type="email"
          value={_.get(formData, 'email', '')}
          error={_.get(formErrors, 'email', '')}
          onChange={onChange}
          onSubmit={validateAndSave}
          disabled={isInProgress(saveState)}
        />
        <div>
          <FormInputField
            name='password'
            className={styles.formPasswordField}
            label="Password"
            placeholder={client.uid ? '••••••••' : 'Enter iPad login password'}
            type={showPassword ? 'text' : 'password'}
            value={_.get(formData, 'password', '')}
            error={_.get(formErrors, 'password', '')}
            onChange={onChange}
            onSubmit={validateAndSave}
            disabled={isInProgress(saveState)}
          />
          <IconButton
            style={{ display: _.isEmpty(formData.password) ? 'none' : 'block' }}
            className={styles.passwordHideIcon}
            onClick={() => setShowPassword(!showPassword)}>
            <HideIcon />
          </IconButton>
        </div>
        <Button
          className={styles.saveButton}
          text={_.isEmpty(client) ? 'Add Client' : 'Save Changes'}
          onClick={validateAndSave}
          loading={isInProgress(saveState)} />
      </Form>
    </Bloc>
  );
};

ClientBloc.defaultProps = {
};
ClientBloc.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ClientBloc;