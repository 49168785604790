/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

const ReviewStarIcon = ({ className, width, height, fill, ...rest }) => {
  const gradId = nanoid();
  return (
    <svg className={[className].join(' ')} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
      <defs>
        <linearGradient id={gradId}>
          <stop offset={`${100 * fill}%`} stopColor="#FFC230" />
          <stop offset={`${100 * fill}%`} stopColor="#ddd" stopOpacity="1" />
        </linearGradient>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3141 10.53C20.3141 10.6773 20.2271 10.838 20.053 11.0121L16.4068 14.5679L17.2706 19.5903C17.2773 19.6371 17.2807 19.7041 17.2807 19.7912C17.2807 19.9318 17.2455 20.0506 17.1752 20.1477C17.1049 20.2448 17.0028 20.2934 16.8688 20.2934C16.7416 20.2934 16.6077 20.2532 16.467 20.1729L11.957 17.8023L7.44695 20.1729C7.29963 20.2532 7.1657 20.2934 7.04517 20.2934C6.90454 20.2934 6.79907 20.2448 6.72876 20.1477C6.65845 20.0506 6.62329 19.9318 6.62329 19.7912C6.62329 19.751 6.62999 19.684 6.64338 19.5903L7.50722 14.5679L3.85097 11.0121C3.68356 10.8313 3.59985 10.6706 3.59985 10.53C3.59985 10.2822 3.78735 10.1282 4.16235 10.0679L9.20477 9.33468L11.4648 4.76437C11.592 4.48982 11.7561 4.35254 11.957 4.35254C12.1579 4.35254 12.322 4.48982 12.4492 4.76437L14.7092 9.33468L19.7516 10.0679C20.1266 10.1282 20.3141 10.2822 20.3141 10.53H20.3141Z"
        fill={`url(#${gradId})`} />
    </svg>
  );
};

ReviewStarIcon.defaultProps = {
  width: 22,
  height: 22,
  fill: 1,
};
ReviewStarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.number,
};

export default ReviewStarIcon;