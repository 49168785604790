/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export const BarChart = ({ data }) => {
  return (
    <div className="chart-container">
      <Bar
        data={{
          labels: data.map(i => i.month),
          datasets: [
            {
              label: 'Positive review',
              data: data.map(i => i.positiveReviewCount),
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1
            },
            {
              label: 'Blocked Reviews',
              data: data.map(i => i.negativeReviewCount),
              backgroundColor: 'rgba(255, 99, 132, 0.6)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            },
            {
              label: 'Total Reviews',
              data: data.map(i => i.totalReviewCount),
              type: 'line',
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: false,
              borderWidth: 2,
              pointBackgroundColor: 'rgba(75, 192, 192, 1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
            }
          ]
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true,
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Reviews'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Months'
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: 'black',
              font: {
                weight: 'bold'
              }
            }
          },
        }}
      // plugins={[ChartDataLabels]}
      />
    </div>
  );
};

BarChart.defaultProps = {
};
BarChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BarChart;