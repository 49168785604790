/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, width, height, color, ...rest }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox='0 0 23 23' {...rest}>
    <g transform="translate(-1124.732 -270.732)">
      <line x2="20" y2="20" transform="translate(1126.5 272.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2.5" />
      <line x1="20" y2="20" transform="translate(1126.5 272.5)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2.5" />
    </g>
  </svg>
);

CloseIcon.defaultProps = {
  width: 23,
  height: 23,
  color: '#202020',
};
CloseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default CloseIcon;